<template>
  <div>
    <!-- 单视图 -->
    <ul v-if="index < 3">
      <li v-if="data.type == 'multiple-input'">
        <p style="margin-bottom:10px;">{{ data.title || data.name }}</p>
        <!-- 选项 -->
        <ul v-if="data.option.length" class="row s_start wrap">
          <li class="row s_center item" v-for="(item, index) in data.option" :key="index" @click="addItem(item)">
            <img v-if="isChoose(item)" class="box" src="./../../../assets/imgs/erp_icon_box_green.png" />
            <img v-if="!isChoose(item)" class="box" src="./../../../assets/imgs/erp_icon_box_disable.png" />
            {{ item.name }}
          </li>
        </ul>
        <!-- 输入框 -->
        <div class="input-content">
          <div class="row s_center flex1">
            <el-input class="input" v-model="data.selfValue.min" @blur="clearInput"></el-input>
            <div style="width:10px;height:1px;margin:0 20px;background-color:#666;" />
            <el-input class="input" v-model="data.selfValue.max" @blur="clearInput"></el-input>
            <p v-if="data.unit" style="margin:0 20px;">{{ "( " + data.unit + " )" }}</p>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="row sb_center mt20">
          <el-button size="small" @click="clearSelect">重置</el-button>
          <div class="row s_center">
            <el-button size="small" @click="doOperate('取消')">取消</el-button>
            <el-button size="small" @click="doOperate('确定')" type="success">确定</el-button>
          </div>
        </div>
      </li>
      <li v-if="data.type == 'multiple'">
        <p style="margin-bottom:10px;">{{ data.title || data.name }}</p>
        <!-- 选项 -->
        <ul v-if="data.option.length" class="row s_start wrap">
          <li class="row s_center item" v-for="(item, index) in data.option" :key="index" @click="addItem(item)">
            <img v-if="isChoose(item)" class="box" src="./../../../assets/imgs/erp_icon_box_green.png" />
            <img v-if="!isChoose(item)" class="box" src="./../../../assets/imgs/erp_icon_box_disable.png" />
            {{ item.name }}
          </li>
        </ul>
        <!-- 按钮 -->
        <div class="row sb_center mt20">
          <el-button size="small" @click="clearSelect">重置</el-button>
          <div class="row s_center">
            <el-button size="small" @click="doOperate('取消')">取消</el-button>
            <el-button size="small" @click="doOperate('确定')" type="success">确定</el-button>
          </div>
        </div>
      </li>
      <li v-if="data.type == 'single'">
        <p style="margin-bottom:10px;">{{ data.title || data.name }}</p>
        <!-- 选项 -->
        <ul v-if="data.option.length" class="row s_start wrap">
          <li class="row s_center item" v-for="(item, index) in data.option" :key="index" @click="addItem(item)">
            <img v-if="data.value && data.value.value == item.value" class="box"
              src="./../../../assets/imgs/erp_icon_box_green.png" />
            <img v-if="!(data.value && data.value.value == item.value)" class="box"
              src="./../../../assets/imgs/erp_icon_box_disable.png" />
            {{ item.name }}
          </li>
        </ul>
        <!-- 按钮 -->
        <div class="row sb_center mt20">
          <el-button size="small" @click="clearSelect">重置</el-button>
          <div class="row s_center">
            <el-button size="small" @click="doOperate('取消')">取消</el-button>
            <el-button size="small" @click="doOperate('确定')" type="success">确定</el-button>
          </div>
        </div>
      </li>
    </ul>
    <!-- 更多 -->
    <div v-if="index > 2" style="height: 500px;" class="flex1">
      <div class="more-view flex1">
        <ul v-for="(item, index) in data" :key="index">
          <li v-if="item.type == 'multiple-input'">
            <p style="margin-bottom:10px;">{{ item.title || item.name }}</p>
            <!-- 选项 -->
            <ul v-if="item.option.length" class="row s_start wrap">
              <li class="row s_center item" v-for="(_item, _index) in item.option" :key="_index"
                @click="addItem(_item, index)">
                <img v-if="isChoose(_item, index)" class="box" src="./../../../assets/imgs/erp_icon_box_green.png" />
                <img v-if="!isChoose(_item, index)" class="box" src="./../../../assets/imgs/erp_icon_box_disable.png" />
                {{ _item.name }}
              </li>
            </ul>
            <!-- 输入框 -->
            <div class="input-content mb20">
              <div class="row s_center flex1">
                <el-input class="input" v-model="item.selfValue.min" @blur="clearInput"></el-input>
                <div style="width:10px;height:1px;margin:0 20px;background-color:#666;" />
                <el-input class="input" v-model="item.selfValue.max" @blur="clearInput"></el-input>
                <p v-if="item.unit" style="margin:0 20px;">{{ "( " + item.unit + " )" }}</p>
              </div>
            </div>
          </li>
          <li v-if="item.type == 'multiple'">
            <p style="margin-bottom:10px;">{{ item.title || item.name }}</p>
            <!-- 选项 -->
            <ul v-if="item.option.length" class="row s_start wrap mb20">
              <li class="row s_center item" v-for="(_item, _index) in item.option" :key="_index"
                @click="addItem(_item, index)">
                <img v-if="isChoose(_item, index)" class="box" src="./../../../assets/imgs/erp_icon_box_green.png" />
                <img v-if="!isChoose(_item, index)" class="box" src="./../../../assets/imgs/erp_icon_box_disable.png" />
                {{ _item.name }}
              </li>
            </ul>
          </li>
          <li v-if="item.type == 'single'">
            <p style="margin-bottom:10px;">{{ item.title || item.name }}</p>
            <!-- 选项 -->
            <ul v-if="item.option.length" class="row s_start wrap mb20">
              <li class="row s_center item" v-for="(_item, _index) in item.option" :key="_index"
                @click="addItem(_item, index)">
                <img v-if="item.value && item.value.value == _item.value" class="box"
                  src="./../../../assets/imgs/erp_icon_box_green.png" />
                <img v-if="!(item.value && item.value.value == _item.value)" class="box"
                  src="./../../../assets/imgs/erp_icon_box_disable.png" />
                {{ _item.name }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- 按钮 -->
      <div class="row sb_center mt20 more-button">
        <el-button size="small" @click="clearMoreSelect">重置</el-button>
        <div class="row s_center">
          <el-button size="small" @click="doOperate('取消')">取消</el-button>
          <el-button size="small" @click="doOperate('确定')" type="success">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Array,
    index: Number // 索引 <3 单个视图  >2 更多视图
  },
  data() {
    return {
      input: ""
    };
  },
  computed: {
    isChoose() {
      return (item, index = "") => {
        if (index) {
          if (
            this.data[index].value &&
            this.data[index].value instanceof Array
          ) {
            return this.data[index].value.some(ele => ele.value == item.value);
          }
        } else {
          if (this.data.value && this.data.value instanceof Array) {
            return this.data.value.some(ele => ele.value == item.value);
          }
        }
        return false;
      };
    },
    data() {
      if (this.index < 3) {
        return this.source[this.index];
      } else {
        return this.source.slice(3);
      }
    }
  },
  methods: {
    addItem(item, index = "") {
      const setValue = targetIndex => {
        const obj = this.source[targetIndex];
        switch (obj.type) {
          case "multiple-input":
            obj.selfValue = {};
            if (!obj.value) {
              obj.value = [item];
            } else {
              const _length = obj.value.length;
              const result = obj.value.filter(ele => ele.value != item.value);
              if (_length == result.length) {
                // 新增
                obj.value = [...obj.value, item];
              } else {
                obj.value = [...result];
              }
            }
            break;
          case "multiple":
            if (!obj.value) {
              obj.value = [item];
            } else {
              const _length = obj.value.length;
              const result = obj.value.filter(ele => ele.value != item.value);
              if (_length == result.length) {
                // 新增
                obj.value = [...obj.value, item];
              } else {
                obj.value = [...result];
              }
            }
            break;
          case "single":
            obj.value = item;
            break;
        }
        this.source[targetIndex] = obj;
        this.$emit("updateFilter", { filter: this.source });
      };
      if (this.index < 3) {
        setValue(this.index);
      } else {
        const _index = index + 3;
        setValue(_index);
      }
    },
    clearInput(index = "") {
      const setValue = targetIndex => {
        const obj = this.source[targetIndex];
        obj.value = "";
        const { min, max } = obj.setValue || {};
        if (min && max) {
          if (min == max) {
            this.$message({
              type: "warning",
              message: "两次输入不能相同！"
            });
          } else if (min > max) {
            obj.setValue.min = max;
            obj.setValue.max = min;
          }
        }
        this.source[targetIndex] = obj;
        this.$emit("updateFilter", { filter: this.source });
      };
      if (this.index < 3) {
        setValue(this.index);
      } else if (this.index > 3) {
        const _index = index + 3;
        setValue(_index);
      }
    },
    clearSelect(index = "") {
      const setValue = targetIndex => {
        const obj = this.source[targetIndex];
        switch (obj.type) {
          case "multiple-input":
            obj.selfValue = { min: "", max: "" };
            obj.value = [];
            break;
          case "multiple":
            obj.value = [];
            break;
          case "single":
            obj.value = "";
            break;
        }
        this.source[targetIndex] = obj;
        this.$emit("updateFilter", { filter: this.source });
      };
      if (this.index < 3) {
        setValue(this.index);
      } else if (this.index > 3) {
        const _index = index + 3;
        setValue(_index);
      }
    },
    clearMoreSelect() {
      let result = [];
      this.source.forEach((item, index) => {
        if (index > 2) {
          if (item.type == "multiple-input") {
            item.value = [];
            item.selfValue = {};
          } else if (item.type == "multiple") {
            item.value = [];
          } else {
            item.value = "";
          }
        }
        result.push(item);
      });
      this.$emit("updateFilter", { filter: result });
    },
    doOperate(operate) {
      this.$emit("updateFilter", { operate });
    }
  }
};
</script>

<style lang="less" scoped>
.item {
  width: 25%;
  padding: 10px;
  color: rgba(0, 0, 0, 0.65);
}

.input-content {
  padding-top: 20px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666;
  margin-top: 40px;
  border-top: 1px solid #f0f0f0;

  .input {
    width: 25%;
  }
}

.box {
  width: 13px;
  height: 13px;
  display: block;
  margin-right: 10px;
  border-radius: 2px;
}

.more-view {
  background: #fff;
  height: 450px;
  overflow: auto;
  margin-right: -20px;
}

.more-button {
  width: 100%;
  height: 30px;
}
</style>
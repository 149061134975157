<template>
  <div class="container">
    <house-header @update-city-select="isShowCitySelect = $event" :tradeType="tradeType" :estateName="estateName"
      @handle-submit="handleSubmit" @update-estate="estateName = $event" @update-search="updateSearch" />
    <div class="content">
      <search-bar :items="params" :updateChildren="updateChildren" @sendParamsEmit="onRefresh"
        @update-children="updateChildren = $event" />
      <div class="flex sb_start" style="margin-top:26px">
        <div class="left-content ">
          <ul v-if="tradeType !== 1" class="sort-wrap flex" :style="{ borderColor: companyConfig.themeColor }">
            <li v-for="item in sort" :key="item.name"
              :class="{ white: choose === item.name, greenBG: choose === item.name }" @click="setChoose(item)"
              class="flex c_center cursor ellipsis">
              <span class="size15 bold">{{ item.name }}</span>
            </li>
          </ul>
          <div v-show="tradeType === 1">
            <div class="content-title flex sb_center">
              <h2 class="bold">
                已为您找到 <span class="green">{{ total }}</span> 套
                <a href="javascript:;" @click="handleScrollTop" class="black">{{ location.cityName + title }}</a>
              </h2>
              <h2 class=" bold" v-show="loading">
                正在加载房源
              </h2>
              <ul class="flex size12 black search-wrap">
                <li class="cursor pr20" @click="handleClear" v-show="!loading">
                  <i class="el-icon-delete size14 pr5"></i>
                  <span style="font-weight: 400;">清空条件</span>
                </li>
              </ul>
            </div>
          </div>
          <ul v-if="tradeType === 1" class="rent-sort-wrap flex">
            <li v-for="item in sort" :key="item.name" :style="{ borderColor: companyConfig.themeColor }"
              :class="{ active: choose === item.name }" @click="setChoose(item)" class="flex c_center cursor ellipsis">
              <span class="size15 bold">{{ item.name }}</span>
            </li>
          </ul>
          <div v-show="(tradeType === 2 || tradeType === 4)" class="result-des flex sb_center">
            <h2 class="size22 bold" v-show="!loading && total != 0">
              共找到 <span class="green">{{ total }}</span> 套
              <router-link :to="path" class="black">{{ location.cityName + title }}</router-link>
            </h2>
            <h2 class="size22 bold" v-show="loading">
              正在加载房源
            </h2>
            <ul class="flex size12 black search-wrap">
              <li class="cursor pr20" @click="handleClear">
                <i class="el-icon-delete size14 pr5"></i>
                <span>清空条件</span>
              </li>
              <li v-if="tradeType === 2" class="cursor" @click="handleSaveSearch">
                <i class="el-icon-star-off size14 pr5"></i>
                <span>保存搜索</span>
              </li>
            </ul>
          </div>
          <div v-if="house.length">
            <ul v-if="tradeType === 4">
              <village-item v-for="item in house" :key="item.guid" :tradeType="tradeType" :houseItem="item" />
            </ul>
            <ul v-else>
              <house-item v-for="item in topHouse" :key="item.guid" :tradeType="tradeType" :houseItem="item" />
              <house-item v-for="item in house" :key="item.guid" :tradeType="tradeType" :houseItem="item" />
            </ul>
          </div>
          <div v-else>
            <el-empty :description="start ? emptyText : '房源加载中···'"></el-empty>
          </div>
          <div style="padding:30px 0" class="flex size12 sb_center pagination-wrap">
            <span class="flex ">
              <router-link to="/" class="col66 pr10">{{ location.cityName + ' ' }} ></router-link>
              <a href="javascript:;" @click="handleScrollTop" class="col66">{{ location.cityName + title }}</a>
            </span>
            <el-pagination :current-page="currentPage" v-if="total > 1" background layout="pager" :page-size="25"
              @current-change="handleCurrentChange" :total="total">
            </el-pagination>
          </div>
        </div>
        <div v-if="tradeType === 2" class="right-layout">
          <div class="map-room column c_center">
            <img src="@/assets/imgs/app_icon_map_room.png" alt="" />
            <router-link to="/map" class="button greenBG" target="_blank">试试地图找房</router-link>
          </div>
          <div class="sider-kandian" v-if="highlightList.length">
            <div class="flex sb_center black size16 bold">
              <span>看点</span>
              <router-link to="/kandian" class="col66 size14" target="_blank">更多</router-link>
            </div>
            <ul>
              <li v-for="item in highlightList" :key="item.guid" class="pt20 size12 flex sb_center">
                <router-link :to="{ path: `/detail/${item.guid}` }" target="_blank" class="black ellipsis"
                  style="max-width:90%">{{ item.title }}</router-link>
                <i class="el-icon-arrow-right black size14"></i>
              </li>
            </ul>
          </div>
          <div v-if="!isSourceERP" class="down-load-app" :class="[fixedDownload ? 'fixed-download ' : '']">
            <span class="bold size16 black">下载{{ companyConfig.firstName + companyConfig.lastName }}APP</span>
            <div class="qr-code">
              <img width="94" height="94" :src="downloadUrl" alt="" />
              <div class="size12 black ">
                <p>扫描上方二维码</p>
                <p>随时查看新房源</p>
                <p class="flex s_center">
                  <router-link class="colaa" to="/">了解更多</router-link>
                  <i class="el-icon-arrow-right colaa size14"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tradeType === 1 && !isSourceERP" class="right-layout">
          <div class="down-load-app column c_center">
            <div class="qr-code">
              <img width="94" height="94" :src="downloadUrl" alt="" />
              <div class="size12 black " :class="[tradeType === 1 ? '' : 'mt20']">
                <p class="bold size16 black">下载{{ companyConfig.firstName }}APP</p>
                <p>扫描上方二维码</p>
                <p>随时查看新房源</p>
                <p class="flex s_center">
                  <router-link class="colaa" to="/">了解更多</router-link>
                  <i class="el-icon-arrow-right colaa size14"></i>
                </p>
              </div>
            </div>
          </div>
          <div style="padding:0;border:none">
            <p class="mb20 bold size20">热推房源</p>
            <ul>
              <recommend-rent-house v-for="houseItem in recommendRentHosue" :key="houseItem.guid"
                :houseItem="houseItem" />
            </ul>
          </div>

        </div>
      </div>
    </div>
    <div class="recommend-estate" v-if="recommendList.length && tradeType === 2">
      <div class="content">
        <h3 class="black size24 bold" style="padding-top:46px">推荐楼盘</h3>
        <ul style="padding:23px 0 46px" class="flex sb_start">
          <estate-item v-for="estate in recommendList" :estate-item="estate" :key="estate.guid" />
        </ul>
      </div>
    </div>
    <save-ok :show-save-ok="showSaveOk" @update-save-ok="showSaveOk = $event" />
    <city-select :show-city-select="isShowCitySelect" @update-city-select="isShowCitySelect = $event" />
  </div>
</template>

<script>
import CitySelect from "@/components/city-select";
import HouseHeader from "@/components/house-header";
import SearchBar from "@/components/search-bar";
import HouseItem from "./components/house-item";
import EstateItem from "./components/estate-item";
import RecommendRentHouse from "./components/recommend-rent-house";
import VillageItem from "./components/village-item";
import houseServer from "@/services/houseServer.js";
import SaveOk from "./components/save-ok";
import util from "@/utils/util";
import tool from "@/utils/tool";
import { mapState } from "vuex";
import { getItem, setItem } from '../../utils/storage'
import Constant from "@/common/Constant";
let that;
export default {
  name: "house",
  props: {},
  data() {
    return {
      tradeType: 2,
      params: [],
      loading: false,
      searchParams: {
        page: 1,
        itemsPerPage: 25,
        estateName: ""
      },
      estateName: "",
      choose: "默认",
      total: 0,
      house: [],
      sort: [],
      recommendList: [],
      recommendRentHosue: [], // 推荐租房
      highlightList: [] /* 看点 */,
      fixedDownload: false,
      title: "二手房",
      emptyText: "",
      isLoadParams: false, //是否加载搜索参数
      showSaveOk: false,
      isWrite: false, //搜索记录过来
      updateChildren: false, //是否更新子组件
      currentPage: 1,
      start: false,
      topHouse: [],
      isShowCitySelect: false
    };
  },
  computed: {
    ...mapState(["location", "isGetLocation", "cityCompany", "searchRecords", "companyConfig", "isSourceERP"]),
    path() {
      return `/house/${this.tradeType}`;
    },
    downloadUrl() {
      return this.companyConfig.downloadUrl
    }
  },
  components: {
    HouseHeader,
    SearchBar,
    HouseItem,
    EstateItem,
    RecommendRentHouse,
    VillageItem,
    SaveOk,
    CitySelect
  },
  watch: {
    $route: {
      handler(newVal, val) {
        if (
          !val ||
          newVal.params.tradeType !== val.params.tradeType ||
          newVal.query.estateName !== val.query.estateName
        ) {
          this.tradeType = +newVal.params.tradeType;
          const estateName = newVal.query.estateName;
          this.estateName = estateName || "";

          this.clearHouse();
          this.searchParams = {
            page: 1,
            itemsPerPage: 25,
            estateName: this.estateName
          };
          this.isLoadParams = false;
          this.start = false
          this.loadData();
        }
      },
      immediate: true //第一次就监听
    },
    location: {
      handler(newVal, preVal) {
        if (!preVal || newVal.cityCode !== preVal.cityCode) {
          this.loadData();
        }
      },
      immediate: true //第一次就监听
    }
  },
  methods: {
    clearHouse() {
      this.searchParams.page = 1;
      this.house = [];
      this.recommendRentHosue = [];
      this.total = 0;
    },
    async loadTopHouse() {
      let params = {}
      params.cityCode = this.location.cityCode;
      params.tradeType = this.tradeType;
      const { data } = await houseServer.loadTopHouse(params);
      if (data) {
        this.topHouse = data
      }
    },
    async loadSaleHouse(params = this.searchParams) {
      if (this.loading) return;
      try {
        this.loading = true;
        params.cityCode = this.location.cityCode;
        params.tradeType = this.tradeType;
        params.area1 = this.location.cityCode;
        const { data } = await houseServer.getHouseList(params);
        if (data) {
          const { records, total } = data;
          if (params.page === 1) {
            this.total = total;
            this.recommendRentHosue = records?.slice?.(0, 5);
          }
          this.house = records || [];
          return;
        }
        this.total = 0;
        this.recommendRentHosue = [];
        this.house = [];
        // console.log(`getSaleHouse >>`, data.records)
      } catch (err) {
        console.log("getSaleHouse :>>", err);
      } finally {
        this.loading = false;
        this.start = true
      }
    },
    async loadSearchItems() {
      const [err, res] = await tool.to(
        util.getSellSearchParams(this.tradeType, this.location.cityCode)
      );
      if (err) {
        console.log("getSellSearchParams :>>", err);
        return;
      }
      this.params = res;
      this.sort = res.filter(ele => ele.key === "sort")[0].data;
      this.choose = this.sort[0].name;
      const {
        query: { param }
      } = this.$route;
      // 我的搜索过来
      if (param && !this.isWrite) {
        const _params = [...this.params];
        param.forEach(_ => {
          switch (_.key) {
            case "site":
              this.isLoadParams = false;
              this.$store.commit("saveLocation", _.value);
              break;
            default:
              _params.forEach(cur => {
                if (cur.key === _.key) {
                  cur.value = _.value;
                }
                if (_.key === "city") {
                  cur.selectedData = _.selectedData;
                }
              });
          }
        });
        this.params = _params;
        util.createSearchObject(this.searchParams, _params);
        this.isWrite = true;
      }
    },
    async loadEstateParams() {
      const [err, res] = await tool.to(
        util.getEstateParams(this.location.cityCode)
      );
      if (err) {
        console.log("getEstateParams :>>", err);
        return;
      }
      if (res) {
        this.params = res;
        this.sort = res.filter(ele => ele.key === "sort")[0].data;
        this.choose = this.sort[0].name;
      }
    },
    async loadRecommendList() {
      const params = {
        page: 1,
        itemsPerPage: 5,
        sort: 0,
        cityCode: this.location.cityCode
      };
      const [err, { data }] = await tool.to(
        houseServer.getNewEstatePage(params)
      );
      if (err) {
        console.log("getNewEstatePage :>>", err);
        return;
      }
      // console.log('getNewEstatePage :>>',data);
      if (data && data.length) {
        this.recommendList = data;
      }
    },
    async loadHighlightList() {
      const params = {
        page: 1,
        itemsPerPage: 5,
        categoryGuid: "580f3e4deefc451d9fee553c1af5a74e",
        companyGuid: getItem(Constant.CITY_COMPANY).cityCompanyGuid,
        pcOrMobile: true
      };
      const [err, { data }] = await tool.to(
        houseServer.customerArticle(params)
      );
      if (err) {
        console.log("customerArticle :>>", err);
        return;
      }
      if (data && data.records && data.records.length) {
        this.highlightList = data.records;
      }
    },
    async loadEstateList() {
      const params = { ...this.searchParams };
      if (this.loading) return;
      try {
        this.loading = true;
        params.cityCode = this.location.cityCode;
        const { data } = await houseServer.getEstateList(params);
        if (data) {
          const { records, total } = data;
          if (params.page === 1) {
            this.total = total;
          }
          this.house = records || [];
          return;
        }
        this.total = 0;
        this.house = [];
        // console.log(`getEstateList >>`, JSON.stringify(records))
      } catch (err) {
        console.log("getEstateList :>>", err);
      } finally {
        this.loading = false;
        this.start = true
      }
    },
    setChoose(item) {
      if (this.choose === item.name) return;
      this.choose = item.name;
      this.searchParams.page = 1;
      this.currentPage = 1;
      this.searchParams.sort = item.code;
      this.loadData();
    },
    onRefresh({ data, isRefresh = true }) {
      this.topHouse = []
      this.params = data;
      const params =
        this.tradeType === 4
          ? util.createEstateSearchObject(this.searchParams, data)
          : util.createSearchObject(this.searchParams, data);
      this.cityName = params.cityName;
      delete params.cityName;
      this.isLoadParams = true;
      this.start = false
      if (isRefresh) {
        this.clearHouse();
        this.loadData();
      }
    },

    async loadData() {
      await this.loadEstate()
      switch (this.tradeType) {
        case 4:
          this.loadEstateList();
          if (!this.isLoadParams) {
            this.loadEstateParams();
            this.isLoadParams = true;
          }
          break;
        default:
          if (!this.isLoadParams) {
            if (this.tradeType === 2) {
              this.loadRecommendList();
              this.loadHighlightList();
            }
            this.isLoadParams = true;
            this.loadSearchItems();
          }
          /* 防止我的搜索页面过来 */
          setTimeout(() => {
            this.loadSaleHouse();
          }, 300);
      }
      this.getText();
    },
    handleCurrentChange(page) {
      this.searchParams.page = page;
      this.currentPage = page;
      this.loadData();
      tool.animate(window, 0);
    },
    handleScroll: tool.throttle(() => {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 1300 && scrollTop < 6000) {
        that.fixedDownload = true;
      } else if (scrollTop < 1300 || scrollTop > 6000) {
        that.fixedDownload = false;
      }
    }, 400),
    getText() {
      let title = "",
        emptyText = "";
      switch (this.tradeType) {
        case 1:
          title = "租房";
          emptyText = "当前城市暂无推荐租房,请切换城市";
          break;
        case 2:
          title = "二手房";
          emptyText = "当前城市暂无推荐二手房,请切换城市";
          break;
        default:
          title = "小区";
          emptyText = "当前城市暂无推荐小区,请切换城市";
      }
      this.title = title;
      this.emptyText = emptyText;
    },
    handleSubmit() {
      this.searchParams = {
        ...this.searchParams,
        estateName: this.estateName
      };
      this.loadData();
    },
    handleClear() {
      this.estateName = "";
      this.choose = this.sort[0]?.name || "";
      this.updateChildren = true;
      this.searchParams = {
        page: 1,
        itemsPerPage: 25,
        estateName: ""
      };
      const _params = [...this.params];
      _params.forEach(_ => {
        switch (_.type) {
          case "region":
            _.value = {};
            _.selectedData = {};
            break;
          case "range_value_input":
            _.value = [];
            _.input = {
              minValue: "",
              maxValue: ""
            };
            break;
          case "single_value_multiple":
            _.value = [];
            break;
          default:
            _.value = {};
        }
      });
      this.loadData();
    },
    handleSaveSearch() {
      const searchRecords = [];
      searchRecords.push({
        key: "site",
        value: this.location
      });
      let flag = false;
      this.params.forEach(({ key, value, data, selectedData }) => {
        if (key !== "sort") {
          let temp;
          if (key === "city") {
            if (value.value) {
              temp = {
                value,
                key,
                selectedData
              };
            }
          } else if (value.length) {
            temp = {
              value,
              key,
              data: data.filter(_ => value.includes(_.value))
            };
          }
          if (temp) {
            searchRecords.push(temp);
            flag = true;
          }
        }
      });
      if (!flag) {
        this.$message.error("请选择筛选条件哦~");
        return;
      }
      this.$store.commit("saveSearchRecords", [
        searchRecords,
        ...this.searchRecords
      ]);
      this.showSaveOk = true;
    },
    updateSearch() {
      this.isLoadParams = false;
      this.isWrite = false;
      this.loadData();
    },
    handleScrollTop() {
      tool.animate(window, 0);
    },
    init() {
      const { query: { isSourceERP = false } } = this.$route;
      if (getItem(Constant.IS_SORCE_ERP)) return
      this.$store.commit("updateState", {
        data: !!isSourceERP,
        key: "isSourceERP"
      });
      setItem(Constant.IS_SORCE_ERP, !!isSourceERP)
    },
    async loadEstate() {
      const { query: { estateGuid } } = this.$route
      if (!estateGuid || (estateGuid && this.estateName)) return
      const { data } = await houseServer.getOldEstateInfo(estateGuid);
      this.estateName = data.name || ""
      this.searchParams.estateName = this.estateName
      this.searchParams.estateGuid = estateGuid
    },
  },
  created() {
    that = this;
    this.loadTopHouse()
    this.init()
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    tool.animate(window, 0);

  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="less" scoped>
.container {
  .left-content {
    width: 910px;

    .content-title {
      font-size: 16px;
      color: #101d37;
      margin-top: 30px;
      margin-bottom: 14px;
      font-weight: 700;

      .clear {
        color: #5680a6;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .result-des {
      height: 55px;
      line-height: 55px;
      border-bottom: 1px solid #f1f1f1;

      a:hover {
        text-decoration: underline;
      }
    }

    .sort-wrap {
      border-bottom: 2px solid;

      li {
        width: 120px;
        height: 48px;
        box-sizing: border-box;
      }
    }

    .rent-sort-wrap {
      position: relative;
      width: 100%;
      border-bottom: 1px solid #eee;

      li {
        font-weight: 700;
        font-size: 13px;
        color: #4a4a4a;
        margin-right: 37px;
        padding-bottom: 15px;
        padding-top: 16px;
        margin-bottom: -1px;

        &.active {
          border-bottom: 2px solid;
        }
      }
    }
  }

  .right-layout {
    width: 180px;

    &>div {
      padding: 35px 0;
      border-bottom: 1px solid #eee;
    }

    .map-room {
      img {
        width: 120px;
        height: 120px;
      }

      .button {
        text-decoration: none;
        margin-top: 26px;
        display: block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 12px;
        line-height: 36px;
        font-weight: 700;
        cursor: pointer;
      }
    }

    .down-load-app {
      border-bottom: none;

      .qr-code {
        margin-top: 28px;

        p {
          height: 23px;
          line-height: 23px;
        }
      }
    }

    .fixed-download {
      width: 180px;
      position: fixed;
      top: 0px;
      z-index: inherit;
    }
  }

  .recommend-estate {
    background-color: #f5f5f6;
  }

  .search-wrap {
    span:hover {
      text-decoration: underline;
    }
  }
}
</style>

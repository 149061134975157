<template>
  <li class="container">
    <router-link
      :to="{
        name: 'new-estate-detail',
        query: {
          guid: estateItem.guid,
        },
      }"
      :title="estateItem.name"
      target="_blank"
      class="pic"
    >
      <img v-if="estateItem.coverImage" :src="estateItem.coverImage|returnImage" alt="" />
      <img v-else :src="coverImage" alt="" />
      <span v-show="estateItem.name" class="description ellipsis">{{
        estateItem.name
      }}</span>
    </router-link>
    <div v-show="estateItem.totalPrices" class="price">
      <span>{{ estateItem.totalPrices }}</span>
      万/套
    </div>
  </li>
</template>


<script>
import Constant from "@/common/Constant";
export default {
  props: {
    estateItem: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      coverImage: Constant.DEFAULT_HOUSE,
    };
  },
  filters:{
    returnImage(url){
        return url+Constant.IMG_HZ
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 218px;
  .pic {
    display: block;
    height: 150px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border: 1px solid #eeeeee;
      border-radius: 2px;
    }
    .description {
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      width: 95%;
      line-height: 150px;
      color: #fff;
      font-weight: 700;
    }
  }
  .price {
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    padding-top: 8px;
    span {
      color: #db4c3f;
      font-weight: 700;
    }
  }
}
</style>




<template>
  <div class="container">
    <div class="content">
      <div class="flex sb_center">
        <div class="flex s_center mb5">
          <h1 class="black size30 bold">{{ house.name }}</h1>
          <div class="tag white greenBG ml10">
            {{ realHouseEstateSellInfoVo.sellStateDesc }}
          </div>
          <div class="tag colb3 grayBG ml10">{{ house.propertyTypeDesc }}</div>
        </div>
        <div v-if="isSourceERP" class="kanfangShare" @click="showKanfangShare = true">
          分享房源
        </div>
      </div>
      <div class="flex" style="margin-top: 15px">
        <div style="width: 565px; margin-right: 70px; position: relative">
          <RentCarousel :imgs="houseImage" :vrUrl="vrUrl" :videoUrl="videoUrl" :coverImage="house.coverImage"
            :videoImageUrl="house.videoImageUrl" :size="'min'" :tradeType="2" />

          <router-link :to="{
            path: '/newEstateTab/3',
            query: {
              guid: guid,
            },
          }" target="_blank" class="img-button row s_center size14" style="color: #101d37"><img
              src="@/assets/icon/lunbo-po-img.png" alt="" />查看全部相册</router-link>
        </div>
        <div class="flex1 house-info-big">
          <div class="flex">
            <p class="size14 whiteGray flex s_center" style="padding-top: 12px">
              参考均价
              <el-tooltip class="item" effect="dark" content="该价格为参考价格，实际价格以开发商为准。" placement="bottom-start">
                <span style="width: 8px"><i class="pice-tip"></i></span>
              </el-tooltip>
            </p>
            <div class="flex1 ml30">
              <div class="flex" v-if="house.averagePrice">
                <p class="flex red s_end height45">
                  <span class="size30">{{ house.averagePrice }}</span>
                  <span class="size14">元/㎡</span>
                </p>
                <p class="flex red ml40 s_end height45">
                  <span class="size30">{{ house.totalPrices }}</span>
                  <span class="size14">万/套</span>
                </p>
              </div>
              <div class="flex" v-else>
                <p class="flex red ml40 s_end height45">
                  <span class="size30">价格待定</span>
                </p>
              </div>
            </div>
          </div>

          <div v-show="!isSourceERP" @click="isShowQRCode = true" style="padding-left: 90px; margin: 10px 0"
            class="flex flex1 mr5 cursor s_center">
            <div v-show="!isSourceERP" @click="isShowQRCode = true" class="tag ml10 green grayBG cupo">
              <img style="width: 12px; height: 12px" src="../../assets/icon/web_icon_price_cut.png" alt="" />
              变价提醒
            </div>
          </div>
          <div class="sb_center mt10 border-b row" style="padding-bottom: 13px">
            <ul class="flex">
              <li class="tag grayBG clo84 mr10 size10" v-for="tag in house.tags" :key="tag">
                {{ tag }}
              </li>
            </ul>
            <div class="flex size10 col51">
              <div v-show="!isSourceERP" @click="isShowQRCode = true" class="flex s_center cursor"
                style="margin-right: 26px">
                <img class="img14 mr5" src="../../assets/icon/erp_icon_wechat.png" alt="" />
                <span> 分享</span>
              </div>
              <div v-show="!isSourceERP" @click="estateAttention" class="flex s_center cursor">
                <i class="el-icon-star-off size16 black" v-if="!isEstateAttention"></i>
                <i class="el-icon-star-on size20 black" v-if="isEstateAttention"></i>
                <span> {{ isEstateAttention ? "取消关注" : "关注" }}</span>
              </div>
            </div>
          </div>
          <div class="border-b pv16 size14">
            <div class="flex mb12 s_center">
              <p class="whiteGray">项目地址</p>
              <span class="col4d ml30">{{ house.address }}</span>
            </div>
            <div v-if="realHouseEstateSellInfoVo.recentlySellTime" class="flex mb12 s_center">
              <p class="whiteGray">最新开盘</p>
              <span class="col4d ml30">{{
                realHouseEstateSellInfoVo.recentlySellTime | datetime
              }}</span>
              <div v-show="!isSourceERP" @click="isShowQRCode = true" class="tag ml10 green grayBG cupo">
                <i class="el-icon-bell"></i>
                开盘提醒
              </div>
              <!-- <div @click="estateAttention" class="tag ml10 green grayBG cupo">
                <i class="el-icon-bell"></i>
                {{ isEstateAttention ? "取消开盘提醒" : "开盘提醒" }}
              </div> -->
            </div>
            <div class="flex mb12 s_center">
              <p class="whiteGray">楼盘户型</p>
              <router-link :to="{
                path: '/newEstateTab/2',
                query: {
                  guid: guid,
                },
              }" target="_blank" class="flex ml30">
                <!-- <router-link
                  :to="{
                    path: '/newEstateTab/2',
                    query: {
                      guid: this.guid,
                    },
                  }"
                  target="_blank"
                  v-for="tag in houseTypeTabs"
                  :key="tag.name"
                  class="mr20 black"
                  >{{ tag.name + "(" + tag.total + ")" }}</router-link
                > -->
                <span v-for="tag in houseTypeTabs" :key="tag.name" class="mr20 black">{{ tag.name + "(" + tag.total +
                  ")" }}</span>
              </router-link>
            </div>
            <div class="flex mb12 s_center">
              <p class="whiteGray">风险提示</p>
              <div class="flex ml30">
                <a class="green" @click="isShowRiskTip = true" href="javascript:;">用户风险提示</a>
              </div>
            </div>
            <router-link :to="{
              path: '/newEstateTab/6',
              query: {
                guid: guid,
              },
            }" target="_blank" class="size14" style="text-decoration: underline; color: #101d37">查看更多楼盘详情</router-link>
          </div>
          <div class="mt10">
            <div v-if="agentData && agentData.guid" class="row s_start info-agent">
              <img class="agent-avatar" :src="agentData.avatar ? agentData.avatar : defaultAvatar" alt="" />
              <div class="ml20 column flex1">
                <div class="row s_center">
                  <span class="agent-name size24">{{
                    agentData.trueName
                  }}</span>
                  <span class="agent-tag size12">新房顾问</span>
                </div>

                <p class="size14 pt5" style="color: #cccccc">
                  {{ agentData.departmentName }}
                </p>
                <p class="size16 pt10" style="color: #fe615a; font-weight: bold"
                  v-if="agentData && (agentData.fixTel || agentData.mobile)">
                  {{ agentData.fixTel || agentData.mobile }}
                </p>
              </div>
              <div>
                <el-button style="width: 140px; box-sizing: border-box; height: 50px" @click="isShowQRCode = true" plain
                  class="tButton chatButton greenBG green size16">
                  <i class="el-icon-chat-dot-round"></i>
                  在线问
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 30px">
        <ul ref="houseTabRef" class="row grayBG" style="height: 54px">
          <div class="flex1 row s_center">
            <li style="line-height: 54px; text-align: center" class="flex1 size16 relative cursor"
              v-for="tab in houseTabs" :key="tab.key">
              <a class="col4d" :class="{ green: tab.choose }" @click="onClickTabItem(tab)">
                {{ tab.title }}
              </a>
              <div v-show="tab.choose" class="absolute tab-bottom greenBG"></div>
            </li>
          </div>

          <span style="padding: 0 20px; margin-left: 120px; line-height: 54px" class="size16"
            v-if="agentData && (agentData.fixTel || agentData.mobile)">咨询电话：{{ agentData.fixTel || agentData.mobile
            }}</span>
        </ul>
        <div style="width: 720px">
          <div id="house-type" class="pt70">
            <h1 class="size28 clo4d bold mb20">户型介绍</h1>
            <ul class="flex" style="margin-bottom: 30px">

              <li v-for="tab in houseTypeTabs" :key="tab.name" @click="getHouseTypes(tab.value)"
                class="tab-item size16 gray cursor" :class="{ green: tab.choose }">
                <span>{{ tab.name + "(" + tab.total + ")" }}</span>
                <div class="tab-item-underline mt15" :class="{ greenBG: tab.choose }"></div>
              </li>
            </ul>
            <ul class="estate-box">

              <router-link :to="{
                path: '/newEstateTab/2',
                query: {
                  guid: guid,
                  houseTypeGuid: estate.guid
                },
              }" target="_blank" class="estate-item" v-for="estate in estateTypes" :key="estate.guid">
                <div class="cover-box">
                  <img class="all" :src="estate.coverImage" :onerror="defaultImg" alt="" />
                </div>
                <div class="estate-item-footer">
                  <div class="flex s_center">
                    <p class="flex size18 col4d">
                      <span v-if="estate.room">{{ estate.room + "室" }}</span>
                      <span v-if="estate.hall">{{ estate.hall + "厅" }}</span>
                      <span v-if="estate.bathRoom">{{
                        estate.bathRoom + "卫"
                      }}</span>
                    </p>
                    <div class="tag size12 white ml5" :class="estate.sellStateDesc == '在售' ? 'greenBG' : 'redBG'
                      " style="height: 18px; line-height: 18px">
                      {{ estate.sellStateDesc }}
                    </div>
                  </div>
                  <p class="flex size12 gray mv5">
                    建面{{ estate.buildingArea }}㎡
                    <span class="ml10" v-if="estate.orientation">({{ estate.orientation + "朝向" }})</span>
                  </p>
                  <p class="red bold size16" v-if="estate.price">
                    约{{ estate.price }}万/套
                  </p>
                  <p class="red bold size16" v-else>价格待定</p>
                  <div class="row mt20">
                    <div v-show="!isSourceERP" @click="isShowQRCode = true"
                      class="estate-item-footer-bt size13 flex1 mr10">
                      订阅变价提醒
                    </div>
                    <div v-show="!isSourceERP" @click="isShowQRCode = true" class="estate-item-footer-bt size13 flex1">
                      一键咨询首付
                    </div>
                  </div>
                </div>
              </router-link>
            </ul>
          </div>

          <div id="estate-info" class="pt70">
            <h1 class="size28 clo4d bold mb20">楼栋信息</h1>
            <div class="flex">
              <div style="width: 520px; height: 400px" class="relative">
                <img class="all" :src="house.coverImage" alt="" />
                <!-- <div class="estate-blue flex size12 cursor">
                                    <div class="flex1 blueBG white">14号楼</div>
                                    <div class="flex1">在售</div>
                                    <div class="triangle-blue"></div>
                                </div>
                                <div class="estate-red flex size12 cursor">
                                    <div class="flex1 white redBG">14号楼</div>
                                    <div class="flex1">在售</div>
                                    <div class="triangle-red"> </div>
                                </div> -->
              </div>
              <div style="max-height: 400px; overflow-y: auto">
                <div class="flex1 size16 mb10" v-for="item in building" :key="item.guid"
                  style="margin-left: 50px; color: #2d3950">
                  <h2 class="mb5">
                    {{ item.name }}
                    <span style="margin-left: 15px">{{
                      item.sellStateDesc
                    }}</span>
                  </h2>
                  <span>{{
                    `共${item.units}个单元，最高${item.totalFloor}层，共${item.totalHouse}户。`
                  }}</span>
                </div>
                <div class="flex1 size16 mb10" style="margin-left: 50px; color: #2d3950" v-if="building.length == 0">
                  <h2 class="mb5">暂无信息</h2>
                </div>
              </div>
            </div>
          </div>
          <div id="estate-dynamic" class="pt70">
            <div class="flex sb_center">
              <h1 class="size28 clo4d bold mb20">楼盘动态</h1>
              <!-- <router-link class="size18 gray"
                                             to="/">查看更多</router-link> -->
            </div>
            <ul v-if="estateDynamic.length > 0">
              <li v-for="(item, index) in estateDynamic" :key="index" style="padding-bottom: 29px" class="mt20"
                :class="{ 'border-b': index !== estateDynamic.length - 1 }">
                <div class="flex s_center mb10">
                  <h1 class="size18 black">{{ item.title }}</h1>
                  <span class="size10 blue ml10">{{ item.categoryName }}</span>
                </div>
                <p style="width: 90%; margin-bottom: 13px" class="size16 black ellipsis">
                  {{ item.content }}
                </p>
                <span>{{ item.date }}</span>
              </li>
            </ul>
            <p v-else class="size18 whiteGray pv20 border-b center">
              暂无楼盘动态
            </p>
            <div class="remind flex sb_center mt20">
              <div>
                <i class="el-icon-bell size12 black"></i>
                <span class="ml5 size16 black">设置楼盘动态提醒，加推盘早知道</span>
              </div>
              <div class="flex">
                <!-- <input type="text"
                                       class="input pl10"
                                       placeholder="请输入手机号"
                                       maxlength="11"> -->
                <div class="set-remind size16 white cursor" style="background: #fe615a" @click="isShowQRCode = true">
                  设置动态提醒
                </div>
              </div>
            </div>
          </div>

          <div id="loan-computed" class="pt70">
            <house-price-computed :house="house" @update-show-code="isShowQRCode = $event" :agent="agentData" />
          </div>
        </div>
        <div ref="mapHouseRef" id="location-nearby" class="pt70" style="max-height: 650px">
          <map-peripheral titleStyle="color:#000;font-size:28px" v-if="house.lat && house.lng" :lat="house.lat"
            :lng="house.lng" :estateName="house.name" />
        </div>
        <div class="pt70" id="preferred-consultant">
          <h1 class="black size30 bold mb20">优选顾问</h1>
          <p class="ke-agent-sj-consult-sub-title row s_center">
            <span class="ke-agent-sj-title-text">专业服务</span>
            <i class="ke-agent-sj-title-icon"></i>
            <span class="ke-agent-sj-title-text">区域解读</span>
            <i class="ke-agent-sj-title-icon"></i>
            <span class="ke-agent-sj-title-text">户型分析</span>
            <i class="ke-agent-sj-title-icon"></i>
          </p>
          <ul v-if="rcommendAgent && rcommendAgent.length" class="agent-warp row warp" style="width: 100%">
            <li v-for="(agent, index) in rcommendAgent" :key="agent.guid" v-show="index < 4"
              class="row s_center mb20 rcommendAgenItem flex1" style="max-width:33%;">
              <div class="rcommendAgenItem-avatar mr10">
                <img class="all" :src="agent.avatar ? agent.avatar : defaultAvatar" alt="" />
              </div>
              <div style="height: 96px" class="flex1 column agent-item-info size12 black mr10">
                <p class="size17" style="font-weight: bold">
                  {{ agent.trueName }}
                  <span class="agent-tag size12" tyle="font-weight: 400;">新房顾问</span>
                </p>
                <p class="size14" style="font-size: 12px; color: #333; vertical-align: middle">
                  {{ agent.departmentName }}
                </p>
                <p class="size14 red" style="font-weight: bold">
                  {{ agent.tell }}
                </p>
                <div class="flex" style="height: 28px">
                  <el-button style="
                      width: 88px;
                      box-sizing: border-box;
                      height: 28px;
                      padding: 0px;
                    " @click="isShowQRCode = true" plain class="tButton chatButton greenBG green size14 row c_center">
                    <i class="el-icon-chat-dot-round"></i>
                    在线咨询
                  </el-button>
                </div>
              </div>
            </li>
          </ul>
          <div v-else style="max-height: 300px;">
            <el-empty description="该楼盘暂无优选顾问"></el-empty>
          </div>
        </div>
        <div id="estate-recommend" class="pt70">
          <h1 class="black size30 bold mb20">好房为你推荐</h1>
          <ul class="flex" v-if="similarityEstate && similarityEstate.length">
            <router-link :to="{
              name: 'new-estate-detail',
              query: {
                guid: item.guid,
              },
            }" :title="item.name" target="_blank" class="recommend-item cupo" v-for="item in similarityEstate"
              :key="item.guid">
              <div class="recommend-cover relative">
                <img class="all" :src="item.coverImage" alt="" />
                <div v-if="item.totalPrice" class="absolute recommend-total-price size16 white redBG">
                  总价 {{ item.totalPrice }} 万/套
                </div>
                <div v-else class="absolute recommend-total-price size16 white redBG">
                  价格待定
                </div>
              </div>
              <div class="flex s_center" style="margin-top: 15px">
                <p class="size16 black ellipsis" style="width: 90%">
                  {{ item.title }}
                </p>
                <div class="ml10 tag white greenBG">住宅</div>
              </div>
              <span style="color: #b4b8c1" class="size14">建面{{ item.areas + "㎡" }}</span>
            </router-link>
          </ul>
          <div v-else style="max-height: 300px;">
            <el-empty description="暂无推荐房源"></el-empty>
          </div>
        </div>
        <div class="disclaimer">
          <p class="det-disclaimer">
            <span>免责声明：</span>
            <span>本网呈现的户型图为第三方提供的非标准的参考户型图或根据第三方提供的户型图通过系统/技术等手段生成的非标准的参考户型图，不应作为您交易的决策依据。户型图上呈现的户型、面积、朝向、内部结构、功能分区仅供展示参考，实际信息请以与开发商签订的商品房买卖合同为准。</span>
            <span>详细请阅读</span>
            <span class="green cupo" @click="isShowRiskTip = true">《重要风险提示》</span>
            <span>。</span>
          </p>
        </div>
      </div>
    </div>
    <risk-tip :show-risk-tip="isShowRiskTip" @update-show-risk-tip="isShowRiskTip = $event" />
    <!-- <Login
      @update-show-login="isShowLogin = $event"
      :show-login="isShowLogin"
    /> -->
    <div v-if="needFxied" class="needFxied grayBG">
      <ul class="row grayBG content relative" style="height: 54px">
        <div class="flex1 row s_center">
          <li style="line-height: 54px; text-align: center" class="flex1 size16 relative cursor"
            v-for="tab in houseTabs" :key="tab.key">
            <a class="col4d" :class="{ green: tab.choose }" @click="onClickTabItem(tab)">
              {{ tab.title }}
            </a>
            <div v-show="tab.choose" class="absolute tab-bottom greenBG"></div>
          </li>
        </div>

        <span style="padding: 0 16px; margin-left: 120px; line-height: 54px" class="size16"
          v-if="agentData && (agentData.fixTel || agentData.mobile)">咨询电话：{{ agentData.fixTel || agentData.mobile
          }}</span>
        <div v-show="needRightFxied" ref="houseInfoFixed" class="house-info-fixed whiteBG">
          <div class="fixed-title flex s_center">
            <h1 class="black size30 bold">{{ house.name }}</h1>
            <div class="tag white greenBG ml10">
              {{ realHouseEstateSellInfoVo.sellStateDesc }}
            </div>
            <div class="tag colb3 grayBG ml10">
              {{ house.propertyTypeDesc }}
            </div>
          </div>
          <div class="flex mb15 s_end">
            <label class="label-title-fixed size14">参考单价</label>
            <p class="size16 red" v-if="house.averagePrice">
              <span style="font-size: 34px">{{ Math.floor(house.averagePrice) }}
              </span>
              元/平
            </p>
            <p class="size16 red" v-else>价格待定</p>
          </div>
          <div class="flex mb15 col9f size14 row">
            <label class="label-title-fixed">项目地址</label>
            <span class="flex1">{{ house.address }} </span>
            <a class="colff" href="#location-nearby" style="margin-left: 60px">地图
            </a>
          </div>
          <div class="flex mb15 col9f size14">
            <label class="label-title-fixed">最新开盘</label>
            <span>{{
              realHouseEstateSellInfoVo.recentlySellTime | datetime
            }}</span>
          </div>
          <div class="flex mb15 col9f size14">
            <label class="label-title-fixed">楼盘户型</label>
            <router-link :to="{
              path: '/newEstateTab/2',
              query: {
                guid: guid,
              },
            }" target="_blank" class="flex ">
              <span v-for="tag in houseTypeTabs" :key="tag.name" class="mr20 black">{{ tag.name + "(" + tag.total + ")"
                }}</span>
            </router-link>
          </div>
          <div class="flex mb15 col9f size14">
            <label class="label-title-fixed">风险提示</label>
            <a class=" green" @click="isShowRiskTip = true" href="javascript:;">用户风险提示</a>
          </div>
          <router-link :to="{
            path: '/newEstateTab/6',
            query: {
              guid: guid,
            },
          }" target="_blank" class="size14" style="text-decoration: underline; color: #101d37">查看更多楼盘详情</router-link>
          <div class="follow flex">
            <div v-show="!isSourceERP" @click="isShowQRCode = true" class="flex c_center flex1 mr5 cursor">
              <img style="width: 12px; height: 12px" src="../../assets/icon/web_icon_price_cut.png" alt="" />
              <span class="ml5 size14 green">变价提醒</span>
            </div>
            <div v-show="!isSourceERP" @click="isShowQRCode = true" class="flex c_center flex1 ml5 cursor">
              <i class="el-icon-bell size12 green"></i>
              <span class="ml5 size14 green">开盘提醒</span>
            </div>
          </div>
          <ul>
            <li v-for="(agent, index) in rcommendAgent" :key="agent.guid" v-show="index < 3" class="flex s_center mb20">
              <div class="fixed-avatar mr10">
                <img class="all" :src="agent.avatar ? agent.avatar : defaultAvatar" alt="" />
              </div>
              <div style="height: 74px" class="flex1 column agent-item-info size12 black mr10">
                <p class="size17" style="font-weight: bold">
                  {{ agent.trueName }}
                  <span class="agent-tag size12" tyle="font-weight: 400;">新房顾问</span>
                </p>
                <p class="size14" style="color: #333">
                  {{ agent.departmentName }}
                </p>
                <p class="size14 red" style="font-weight: bold">
                  {{ agent.tell }}
                </p>
              </div>
              <div class="flex" style="height: 74px">
                <el-button style="width: 120px; box-sizing: border-box; height: 40px" @click="isShowQRCode = true" plain
                  class="tButton chatButton greenBG green size14">
                  <i class="el-icon-chat-dot-round"></i>
                  在线咨询
                </el-button>
              </div>
            </li>
          </ul>
        </div>
      </ul>
    </div>

    <qr-code-dialog :show-code="isShowQRCode" :page="8" :houseGuid="guid" @update-show-code="isShowQRCode = $event" />
    <kf-qr-code-dialog :show-code="showKanfangShare" :page="3" :estateGuid="guid"
      @update-show-code="showKanfangShare = false" />

    <div v-if="!isSourceERP" class="follow-bar-wrap" ref="followBarWrap">
      <div class="follow-bar">
        <div class="follow-bar-qrcode">
          <img :src="companyConfig.downloadUrl" alt="" width="140" height="140">
        </div>
        <div class="follow-bar-content">
          <h2 class="follow-bar-title">下载{{ companyConfig.firstName }}，获取<span class="follow-bar-resblock">{{ house.name
              }}</span>更多信息！</h2>
          <div class="follow-bar-subtitle">现在扫码下载{{ companyConfig.firstName }}，尊享专属经纪人服务</div>
        </div>
        <div @click="closeFollowBarWrap" class="follow-bar-close"></div>
      </div>
    </div>

    <div class="follow-bar-pickup-wrap" ref="followBarPickupWrap">
      <div class="follow-bar-pickup">
        <div class="follow-bar-pickup-qrcode">
          <img width="80" height="80" :src="companyConfig.downloadUrl" alt="">
        </div>
        <div class="follow-bar-pickup-text">下载App</div>
        <div @click="showFollowBarWrap" class="follow-bar-pickup-spread">
          <i></i>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import RentCarousel from "../../components/RentCarousel.vue";
import Constant from "../../common/Constant.js";
import MapPeripheral from "../../components/MapPeripheral.vue";
import RiskTip from "../../components/RiskTip.vue";
// import houseServer from "../../services/houseServer";
import realHouseServer from "../../services/realHouseServer";
import userServer from "../../services/userServer.js";
import houseServer from "../../services/houseServer.js";
import newEstateService from "../../services/newEstateService";
import tool from "../../utils/tool.js";
import HousePriceComputed from "../../components/HousePriceComputed.vue";
import Dialog from "../../components/Dialog.vue";
import KfQrCodeDialog from "../../components/KanfangShareCode.vue";
import { mapState } from "vuex";

export default {
  name: "NewHouseDetail",
  data() {
    return {
      lng: 104.071,
      lat: 30.56,
      guid: "",
      page: 2,
      isShowQRCode: false, //显示二维码
      title: "",
      videoUrl: "",
      vrUrl: "",
      house: {},
      realHouseEstateMgtVo: {},
      realHouseEstateSellInfoVo: {},
      realHouseEstateSellLicenceVo: {},
      houseType: [
        {
          title: "图片",
          choose: true,
          source: [],
        },
        {
          title: "视频",
          choose: false,
          source: [],
        },
        {
          title: "VR",
          choose: false,
          source: [],
        },
      ],
      houseImage: [],
      type: "住宅",
      agentData: {},
      rcommendAgent: [],
      houseTabs: [
        {
          title: "户型介绍",
          choose: true,
          key: 1,
          id: "#house-type",
        },

        {
          title: "楼栋信息",
          choose: false,
          key: 2,
          id: "#estate-info",
        },
        {
          title: "楼盘动态",
          choose: false,
          key: 3,
          id: "#estate-dynamic",
        },
        {
          title: "贷款计算",
          choose: false,
          key: 4,
          id: "#loan-computed",
        },
        {
          title: "周边配套",
          choose: false,
          key: 5,
          id: "#location-nearby",
        },
        {
          title: "楼盘推荐",
          choose: false,
          key: 6,
          id: "#estate-recommend",
        },
      ],
      houseTypeTabs: [],
      estateTypes: [],
      estateDynamic: [
        {
          title: "户型面积为81-129㎡的住宅在售",
          content:
            "项目目前热销中，总价80万-130万元/套，产品为户型面积81-129㎡的住宅，70年产权，物业费3.5元/㎡....",
          date: "2021年04月14日",
        },
        {
          title: "户型面积为81-129㎡的住宅在售",
          content:
            "项目目前热销中，总价80万-130万元/套，产品为户型面积81-129㎡的住宅，70年产权，物业费3.5元/㎡总价80万-130万元/套，产品为户型面积81-129㎡",
          date: "2021年04月14日",
        },
      ],
      similarityEstate: [],
      recommendHouse: [],
      building: [],

      estateActivity: [],
      isShowRiskTip: false,
      isShowLogin: false,

      houseTypes: [
        {
          title: "VR",
          key: 0,
          value: "vr",
          choose: true,
        },
        {
          title: "视频",
          key: 1,
          value: "video",
          choose: false,
        },
        {
          title: "图片",
          key: 2,
          value: "effect",
          choose: false,
        },
        {
          title: "户型",
          key: 3,
          value: "houseType",
          choose: false,
        },
      ],
      deleteTags: [],

      //关注状态
      isEstateAttention: false,
      estateAttentionValue: "",
      needFxied: false,
      needRightFxied: false,
      href: "",
      loading: false,

      defaultAvatar: Constant.DEFAULT_AVATAR,

      showKanfangShare: false,//显示看房分享二维码
    };
  },
  computed: {
    ...mapState(["user", "companyConfig", "isSourceERP"]),
    defaultImg() {
      return `this.src="${Constant.DEFAULT_HOUSE}"`
    }
  },
  components: {
    RentCarousel,
    MapPeripheral,
    RiskTip,
    HousePriceComputed,
    QrCodeDialog: Dialog,
    KfQrCodeDialog: KfQrCodeDialog,
  },
  filters: {},
  methods: {
    toMimiWeChat() {
      this.isShowQRCode = true;
    },

    goDetail(guid) {
      // console.log(guid)
      this.$router.push({
        name: "new-estate-detail",
        query: { guid: guid },
      });
      this.$router.go(0);
    },
    async getNewEstateData() {
      const { data } = await newEstateService.getNewEstateData({
        estateGuid: this.guid,
      });
      //   console.log("getNewEstateData >>", data);
      const { lat, lng } = data.realHouseEstateVo;
      this.house = data.realHouseEstateVo;
      //   console.log("getNewEstateData >>", data.realHouseEstateVo);
      this.lat = lat;
      this.lng = lng;
      this.realHouseEstateMgtVo = data.realHouseEstateMgtVo;
      this.realHouseEstateSellInfoVo = data.realHouseEstateSellInfoVo;
      this.realHouseEstateSellLicenceVo = data.realHouseEstateSellLicenceVo;
      let tag = [];
      if (data && data.realHouseEstateVo.tag) {
        let tagss = JSON.parse(data.realHouseEstateVo.tag);
        tagss.forEach((item, index) => {
          if (index < 4) {
            tag.push(item);
          }
        });
      }
      this.house.tags = tag;

      let coverImage =
        data.realHouseEstateVo.coverImage || Constant.DEFAULT_HOUSE;
      this.title = data.realHouseEstateVo.propertyTypeDesc;

      this.vrUrl = data.realHouseEstateVo.vrUrl;

      this.videoUrl = data.realHouseEstateVo.videoUrl;

      this.getSimilarityEstate();
      this.getAgentData();
    },
    async getHouseTypes(room) {
      let param = {
        estateGuid: this.guid,
        propertyType: 2,
      };
      if (room) {
        param.room = room;
      }
      const { data } = await newEstateService.getHouseTypes(param);
      let houseTypes = data;
      let houseTypeTabs = [];
      if (houseTypes) {
        //筛选条件只执行一次
        if (this.houseTypeTabs.length === 0) {
          for (let index = 0; index < houseTypes.houseType.length; index++) {
            let houseTypeParam = {
              name: "",
              value: "1",
              total: "0",
              choose: false,
            };
            if (houseTypes.houseType[index].name == 0) {
              houseTypeParam.name = "全部";
              houseTypeParam.value = "";
            } else {
              houseTypeParam.name = houseTypes.houseType[index].name + "居室";
              houseTypeParam.value = houseTypes.houseType[index].name;
            }

            houseTypeParam.total = houseTypes.houseType[index].value;

            if (index === 0) {
              houseTypeParam.choose = true;
              // this.houseTypeValue = houseTypeParam.value;
            }
            houseTypeTabs.push(houseTypeParam);
          }

          this.houseTypeTabs = houseTypeTabs;
          // console.log('this.houseTypeTabs', this.houseTypeTabs)
        }

        if (houseTypes.realHouseEstateHouseType.length > 0) {
          this.estateTypes = houseTypes.realHouseEstateHouseType;
        }
        if (room) {
          this.houseTypeTabs.forEach((item) => {
            if (item.value == room) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          });
        }
      }
    },
    async loadModalInfo() {
      const { data } = await newEstateService.loadModalInfo({
        estateGuid: this.guid,
      });
      let building = [];
      data.forEach((item) => {
        if (item.sellStateDesc == "在售") {
          building.push(item);
        }
      });
      this.building = building;
    },
    async loadEstateDynamic() {
      let param = {
        estateGuid: this.guid,
        categoryValue: 0,
        page: 1,
        itemsPerPage: 3,
      };
      const { data } = await newEstateService.loadEstateDynamic(param);
      this.estateDynamic = data.records;
      // console.log('loadEstateDynamic', data.records)
    },

    //相似楼盘
    async getSimilarityEstate() {
      let params = {
        cityCode: this.$store.state.location.cityCode,
        estateGuid: this.guid,
        // companyGuid:this.house.companyGuid
      };
      const { data } = await newEstateService.getSimilarityEstate(params);
      // console.log('getSimilarityEstate', data)
      data.forEach((item, index) => {
        if (index < 4) {
          this.similarityEstate.push(item);
        }
      });
    },
    //获取经纪人
    async getAgentData() {
      let params = {
        estateGuid: this.guid,
        limitCount: 10,
      };
      const { data } = await newEstateService.getAgentData(params);

      this.rcommendAgent = data;
      this.agentData = data[0];
      this.rcommendAgent.forEach((item) => {
        if (item.fixTel ) {
          item.tell = item.fixTel;
        } else {
          item.tell = item.mobile;
        }
      });
    },

    //获取图片
    async loadHouseImags() {
      const { data } = await realHouseServer.getHouseresource(
        this.guid
      );
      this.houseImage = data;
      //   console.log(`getHouseresource >>`, data);

      //   this.houseTypes = houseTypes;
    },

    //添加关注
    async estateAttention() {
      if (this.user) {
        const [err, { data }] = await tool.to(
          houseServer.estateAttention({ estateGuid: this.guid })
        );
        if (err) {
          this.$message.error(err.message);
          return;
        }
        this.getIsEstateAttention();
      } else {
        //弹登录框
        this.$store.commit("updateState", {
          data: true,
          key: "showLogin",
        });
      }
    },
    //获取关注状态
    async getIsEstateAttention() {
      const [err, { data }] = await tool.to(
        houseServer.isEstateAttention({
          estateGuid: this.guid,
        })
      );
      if (err) {
        console.log("isEstateAttention :>>", err);
      }
      // console.log("getIsEstateAttention :>>", data);
      this.isEstateAttention = data;
    },
    onScroll() {

      let scrollTop = document.documentElement.scrollTop;
      let offsetTop = this.$refs.houseTabRef.offsetTop;
      let mapScrollTop = this.$refs.mapHouseRef.offsetTop;
      if (scrollTop > offsetTop) {
        this.needFxied = true;
        let houseTabs_ = this.deepCopy(this.houseTabs);
        houseTabs_.forEach((item, index) => {
          if (index < houseTabs_.length - 1) {

            if (
              scrollTop > item.maxTop &&
              scrollTop < item.maxTop + item.height
            ) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          } else {
            if (scrollTop > item.maxTop) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          }
        });
        this.houseTabs = houseTabs_;
        if (scrollTop < mapScrollTop - 700) {
          this.needRightFxied = true;
        } else {
          this.needRightFxied = false;
        }
      } else {
        this.needFxied = false;
        this.needRightFxied = false;
      }
    },
    onClickTabItem(item) {
      this.clearIntervalAll()
      if (item.choose) return;
      tool.animate(document.querySelector(item.id), item.maxTop + 10)
      let houseTabs = this.deepCopy(this.houseTabs);
      houseTabs.forEach((ele) => {
        if (item.key === ele.key) {
          ele.choose = true;
        } else {
          ele.choose = false;
        }
      });
      this.houseTabs = houseTabs;
    },
    clearIntervalAll() {
      const house = [...this.houseTabs]
      house.forEach(house => {
        const node = document.querySelector(house.id)
        node.timer && clearInterval(node.timer)
      })
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    closeFollowBarWrap() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      let toDistance = 0;
      let time = setInterval(() => {
        toDistance = toDistance - 1;

        this.$refs.followBarWrap.style.left = toDistance + "%";

        if (toDistance <= -100) {
          clearInterval(time);
          let toDistanceX = -150;
          let timeX = setInterval(() => {
            toDistanceX = toDistanceX + 2;
            this.$refs.followBarPickupWrap.style.left = toDistanceX + "px";

            if (toDistanceX >= 0) {
              clearInterval(timeX);

              this.loading = false;
            }
          }, 5);
        }
      }, 8);
    },
    showFollowBarWrap() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let toDistanceX = 0;
      let timeX = setInterval(() => {
        toDistanceX = toDistanceX - 2;
        this.$refs.followBarPickupWrap.style.left = toDistanceX + "px";

        if (toDistanceX <= -150) {
          clearInterval(timeX);
          let toDistance = -100;
          let time = setInterval(() => {
            toDistance = toDistance + 1;

            this.$refs.followBarWrap.style.left = toDistance + "%";
            if (toDistance >= 0) {
              clearInterval(time);
              this.loading = false;
            }
          }, 8);
        }
      }, 5);
    },
    updateHouseTabs() {
      let houseTabs = this.deepCopy(this.houseTabs);
      houseTabs.forEach((item, index) => {
        const scrollTop = document.querySelector(item.id).offsetTop;
        item.height = document.querySelector(item.id).clientHeight;
        item.maxTop = scrollTop;
        if (index === houseTabs.length - 2 && !this.house.lat) {
          houseTabs[index] = {
            ...item,
            title: "推荐顾问",
            id: "#preferred-consultant",
          }
        }
        if (index === houseTabs.length - 1) {
          houseTabs[index] = {
            ...item,
            maxTop: scrollTop + 50
          }
        }
      });
      this.houseTabs = houseTabs;
    }
  },
  created() {
    this.guid = this.$route.query.guid;
    this.href = window.location.href;
    this.loadHouseImags();
    this.getNewEstateData();
    this.getHouseTypes();
    this.loadModalInfo();
    this.loadEstateDynamic();
    if (this.user) {
      //获取关注状态
      //添加浏览记录
      this.getIsEstateAttention();
    }
  },
  mounted() {

    setTimeout(() => {
      this.updateHouseTabs()
    }, 500)
    window.onscroll = tool.throttle(this.onScroll, 200);
  },
  destroyed() {
    window.onscroll = null;
  },
};
</script>
<style scoped lang="less">
.col9f {
  color: #9fa2ab;
}

.colff {
  color: #777980;
}

.mt70 {
  margin-top: 70px;
}

.pv16 {
  padding: 16px 0;
}

.mb12 {
  margin-bottom: 12px;
}

.col51 {
  color: #515151;
}

.img14 {
  width: 14px;
  height: 12px;
}

.height45 {
  height: 45px;
}

.container {
  padding-top: 30px;
}

.tag {
  padding: 0 6px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  white-space: nowrap;
}

.house-info-big {
  box-shadow: 0 0 15px 0 rgba(68, 68, 79, 0.1);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 40px;
}

// .house-info-fixed {
//   box-shadow: 5px 5px 15px 0 rgb(68 68 79 / 10%) !important;
//   border-radius: 2px;
//   box-sizing: border-box;
//   padding: 20px 40px 0;
// }

.label-title {
  width: 86px;
}

.rcommend-agent {
  padding-top: 30px;
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.agent-item-info {
  justify-content: space-around;
}

.tab-bottom {
  width: 50%;
  left: 50%;
  bottom: 0;
  height: 3px;
  transform: translateX(-50%);
}

.tab-item {
  margin-right: 54px;
  //   text-decoration: underline;
}

.tab-item-underline {
  height: 2px;
  border-radius: 1px;
}

.estate-box {
  display: flex;
  //   overflow-x: auto;
  flex-wrap: wrap;
}

.estate-item {
  width: 220px;
  margin: 20px 0;
  flex-shrink: 0;
  border: 1px solid #cccccc;
}

.estate-item:not(:nth-of-type(3n)) {
  margin-right: 20px;
}

.estate-item-footer {
  padding: 10px 10px;
  //   border-top:1px solid #cccccc;
  border-top: none;

  .estate-item-footer-bt {
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #707070;
    border-radius: 2px;
    cursor: pointer;
    color: #9399a5;
  }
}

.cover-box {
  width: 100%;
  height: 169px;
  border-bottom: 1px solid #ccc;
}

.estate-red,
.estate-blue {
  width: 110px;
  height: 26px;
  background-color: #fff;
  position: absolute;
}

.estate-blue {
  top: 99px;
  right: 135px;
}

.estate-red {
  bottom: 194px;
  right: 180px;
}

.estate-red div,
.estate-blue div {
  text-align: center;
  line-height: 26px;
}

.triangle-red,
.triangle-blue {
  position: absolute;
  width: 0;
  height: 0;
  border-width: 4px;
  border-style: solid;
  left: 20px;
  bottom: -8px;
}

.triangle-blue {
  border-color: #3072f6 transparent transparent #3072f6;
}

.triangle-red {
  border-color: #d13c3c transparent transparent #d13c3c;
}

.remind {
  padding: 15px 30px;
  border: 1px solid #eff0f6;
  border-radius: 2px;
}

.input {
  width: 200px;
  background-color: #f8f8f9;
  border: none;
  outline: none;
}

.set-remind {
  padding: 0 22px;
  height: 40px;
  line-height: 40px;
}

.recommend-total-price {
  bottom: 20px;
  left: 0;
  padding: 0 5px;
  height: 25px;
  line-height: 25px;
}

.recommend-cover {
  height: 220px;
}

.recommend-item {
  width: 267px;
  margin-bottom: 40px;
}

.recommend-item:not(:nth-of-type(4n)) {
  margin-right: 30px;
}

.house-info-fixed {
  box-shadow: 0 0 15px 0 rgba(68, 68, 79, 0.1);
  padding: 0 22px 0 29px;
  position: absolute;
  right: 0;
  top: 54px;
  width: 400px;
  box-sizing: border-box;
}

.fixed-title {
  margin: 25px 0 17px;
}

.label-title-fixed {
  color: #9fa2ab;
  margin-right: 34px;
}

.follow {
  margin: 18px 0 26px;
}

.follow div {
  background-color: #f7fffc;
  border-bottom: 1px solid #e6e6e6;
  height: 32px;
  cursor: pointer;
}

.fixed-avatar {
  width: 54px;
  height: 70px;
}

.needFxied {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.info-agent {
  .agent-avatar {
    width: 74px;
    height: 100px;
    // border: 1px solid #707070;
  }

  .agent-name {
    color: #101d37;
    font-weight: bold;
    margin-right: 40px;
  }
}

.agent-tag {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}

.tButton {
  background: #00ae66;
  color: #fff;
  border-radius: 1px;

  .i {
    color: #fff;
  }
}

.phoneButton:hover {
  background: #f0fef8;
  box-sizing: border-box;
  border: #00ae66 1px solid;
  color: #00ae66;

  .i {
    color: #00ae66;
  }
}

.chatButton {
  background: #f0fef8;
  border-radius: 1px;
  border: #00ae66 1px solid;
  color: #00ae66;

  .i {
    color: #00ae66;
  }
}

.chatButton:hover {
  box-sizing: border-box;
  background: #fff;
  color: #00ae66;
  border: #00ae66 1px solid;

  .i {
    color: #00ae66;
  }
}

.img-button {
  position: absolute;
  top: 275px;
  left: 5px;
  background: #fff;
  border-radius: 2px;
  height: 32px;
  line-height: 32px;
  background: #fff;
  border-radius: 2px;
  padding: 0 7px;
  z-index: 1;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;

  img {
    width: 15px;
    height: 12px;
    margin-right: 6px;
  }
}

.pice-tip {
  margin-left: 4px;
  cursor: pointer;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url("../../assets/icon/icon_tip.png") no-repeat;
  //   background: url("@/assets/icon/icon_tip.png") no-repeat;
  background-size: 12px 12px;
}

.ke-agent-sj-consult-sub-title {
  margin-top: 10px;

  .ke-agent-sj-title-text {
    font-size: 14px;
    color: #9399a5;
    letter-spacing: 0;
    line-height: 14px;
    vertical-align: middle;
    font-weight: 400;
  }

  .ke-agent-sj-title-icon {
    display: inline-block;
    width: 0;
    height: 10px;
    line-height: 10px;
    border: 1px solid #979797;
    border: 1px solid hsla(0, 0%, 59.2%, 0.23);
    margin: 0 8px;
    vertical-align: middle;
  }
}

.agent-warp {
  padding: 0;
  list-style: none;
  margin: 20px 0 0;
  white-space: nowrap;
}

.rcommendAgenItem {
  width: 276px;
  padding: 20px 0;
  margin-right: 0px;
  background: #fff;
  -webkit-box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 0px 3px #ccc;
  -webkit-border-radius: 2px;
  border-radius: 2px;

  .rcommendAgenItem-avatar {
    img {
      margin-left: 20px;
      width: 72px;
      height: 96px;
    }
  }
}

.det-disclaimer {
  height: 20px;
  padding: 0 0 52px 16px;
  margin: 0;
  background: url(../../assets/icon/detail_new_disclaimer.png?dd64809…);

  background-repeat: no-repeat;
  background-position: 0 5px;
  color: #9399a5;
  font-size: 0;

  span {
    font-size: 12px;
    line-height: 20px;
  }
}

.follow-bar-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 202px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  box-sizing: border-box;

  .follow-bar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    .follow-bar-qrcode {
      margin: 0 24px 0 144px;
      width: 140px;
      height: 140px;
      background-size: contain;

      img {
        border: 0;
        vertical-align: top;
      }
    }

    .follow-bar-title {
      margin-bottom: 19px;
      font-size: 38px;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;

      .follow-bar-resblock {
        color: #3072f6;
        line-height: 32px;
      }
    }

    .follow-bar-subtitle {
      font-size: 18px;
      color: #fff;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
    }

    .follow-bar-close {
      position: absolute;
      top: 32px;
      right: 36px;
      width: 26px;
      height: 26px;
      cursor: pointer;
      z-index: 1;
    }

    .follow-bar-close:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background: #fff;
      display: inline-block;
    }

    .follow-bar-close:after,
    .follow-bar-close:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background: #fff;
      display: inline-block;
    }

    .follow-bar-close:before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .follow-bar-close:after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

.follow-bar-pickup-wrap {
  position: fixed;
  left: -150px;
  bottom: 37px;
  width: 150px;
  height: 166px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;

  .follow-bar-pickup {
    position: relative;
    height: 100%;
    text-align: center;

    .follow-bar-pickup-qrcode {
      margin-top: 28px;
      margin-bottom: 12px;
      width: 80px;
      height: 80px;
      display: inline-block;
      background-size: contain;

      img {
        border: 0;
        vertical-align: top;
      }
    }

    .follow-bar-pickup-text {
      font-size: 18px;
      color: #fff;
      display: inline-block;
    }

    .follow-bar-pickup-spread {
      position: absolute;
      width: 39px;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.estate-box {
  .all {
    object-fit: contain;
  }
}

.pt70 {
  padding-top: 70px;
}

.kanfangShare {
  width: 100px;
  height: 40px;
  background: #ff9000;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

}

.kanfangShare:hover {
  background: #ff9f00;
}
</style>
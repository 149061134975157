<template>
  <div v-show="showCode" class="modal " @mousewheel.prevent>
    <!-- @mousewheel.prevent 阻止默认事件 -->
    <div class="modal-inner">
      <div class="close-btn cursor" @click="$emit('update-show-code', false)">
        <i class="el-icon-close size26"></i>
      </div>
      <h1 class="size20 gray mb10">看房扫码分享

      </h1>
      <span class="size14 colb3">打开小智看房App，即刻分享</span>
      <div class="flex" style="margin-top:33px">
        <div class=" clum CenterCenter" style="border: 1px solid #000;padding:15px">
          <div id="qrcode" title="" ref="qrcode"></div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import parameterServer from "../services/parameterServer.js";
import { mapState } from "vuex";
import QRCode from 'qrcodejs2';
export default {
  name: "kf-qr-code",
  props: {
    showCode: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      // required: true
      //1租2售，3新房
    },
    houseNo: {
      type: String,
      //   required: true,
    },
    estateGuid: {
      type: String,
      //   required: true,
    },

  },
  data() {
    return {
      qrcode: ''
    }
  },

  methods: {
    creatQrCode() {
      let data = {
        event: 'qrShareHouse',
        data: {
          houseNo: this.houseNo,
          tradeType: this.page
        }
      };
      if (this.page == 3) {
        data = {
          event: 'qrShareHouse',
          data: {
            estateGuid: this.estateGuid,
          }
        };
      }
      this.$refs.qrcode.innerHTML = ''
      this.qrcode = new QRCode(this.$refs.qrcode, {
        text: JSON.stringify(data), //二维码内容字符串
        width: 158, //图像宽度
        height: 158, //图像高度
        colorDark: '#000000', //二维码前景色
        colorLight: '#ffffff', //二维码背景色
        correctLevel: QRCode.CorrectLevel.H, //容错级别
      })
      this.$refs.qrcode.title = ''
    }


  },
  mounted() {
    this.creatQrCode()
  },
  watch: {
    showCode(val) {
      if (val) {


        this.creatQrCode();
      }
    },
  },
};
</script>
<style scoped>
.pb7 {
  padding-bottom: 7px;
}

.modal-inner {
  border-radius: 4px;
  background-color: #fff;
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  padding: 36px 47px;
  box-sizing: border-box;
}

.close-btn {
  position: absolute;
  right: 14px;
  top: 18px;
}

.code {
  width: 130px;
  height: 130px;
}

.f12 {
  font-size: 12px;
}

.ml5 {
  margin-left: 5px;
}

.clum {
  display: flex;
  flex-direction: column;
}

.CenterCenter {
  justify-content: center;
  align-items: center;
}

.guidStyle {
  font-size: 12px;
  color: #bbb;
  padding: 5px;
}
</style>
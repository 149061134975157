<template>
  <div class="container">
    <div class="content">
      <!-- 搜索 -->
      <header-wrap :houseNums="houseNums" />
      <div style="height: 20px;" />
      <!-- 推荐列表 -->
      <house-list v-for="menu in menuList" :key="menu.tradeType" :menu="menu" />
    </div>
    <footer-download v-if="!isSourceERP" :isShowDownload="isShowDownload"
      @close-footer-download="isShowDownload = $event" />
  </div>
</template>
<script>
import FooterDownload from "./component/footer-download.vue";
import HeaderWrap from "./component/header-wrap.vue";
import HouseList from "./component/house-list.vue";
import { mapState } from "vuex";
import tool from "@/utils/tool";
import Constant from "@/common/Constant";
import houseServer from "@/services/houseServer.js";
import { removeItem, getItem } from '@/utils/storage'


export default {
  name: "home",
  props: {},
  computed: {
    ...mapState(["location", "companyConfig", "isSourceERP"])
  },
  data() {
    return {
      isShowDownload: true,
      menuList: [
        {
          tradeType: 2,
          title: "二手好房",
          subTitle: "好房源那么多，我们为你精选",
          rightTitle: "二手房",
          page: "/house/2",
          house: [],
          start: false
        },
        {
          tradeType: 4,
          title: "小区精选",
          subTitle: "纵览小区，恋上这座城",
          rightTitle: "小区",
          page: "/house/4",
          house: [],
          start: false
        },
        {
          tradeType: 3,
          title: "优选新房",
          subTitle: "真实信息准确同步，楼盘动态一手掌握",
          rightTitle: "新房",
          page: "/loupan",
          house: [],
          start: false
        },
        {
          tradeType: 1,
          title: "品质租房",
          subTitle: "",
          rightTitle: "租房",
          page: "/house/1",
          house: [],
          start: false
        }
      ],
      searchParam: {
        page: 1,
        itemsPerPage: 4,
        sort: 0
      },
      houseNums: [],
      topHouse: []
    };
  },
  components: {
    FooterDownload,
    HeaderWrap,
    HouseList
  },

  watch: {
    location(newVal, preVal) {
      if (newVal.cityCode !== preVal.cityCode) {
        this.loadData();
      }
    }
  },
  methods: {
    async loadTopHouse() {
      let params = {}
      params.cityCode = this.location.cityCode;
      params.tradeType = 2;
      const { data } = await houseServer.loadTopHouse(params);
      this.topHouse = data || []
      this.loadSaleHouse();
    },

    async loadSaleHouse() {
      const { cityCode, cityName } = this.location;
      const params = {
        ...this.searchParam,
        itemsPerPage: 8,
        tradeType: 2,
        cityCode
      };
      const [err, { data }] = await tool.to(houseServer.getHouseList(params));
      if (err) {
        console.log("getHouseList :>>", err);
        return;
      }
      if (data?.records?.length) {
        const _houseNums = [];
        _houseNums.push(`${cityName}在售二手房 ${data.total} 套 `);
        this.menuList[0].house = [...this.topHouse, ...data.records.slice(0, 8 - this.topHouse.length)]
        this.houseNums = _houseNums;
      }
      this.menuList[0].start = true
    },
    async loadRentHouse() {
      const params = {
        ...this.searchParam,
        tradeType: 1,
        cityCode: this.location.cityCode
      };
      const [err, { data }] = await tool.to(houseServer.getHouseList(params));
      if (err) {
        console.log("loadRentHouse :>>", err);
        return;
      }
      if (data && data.records && data.records.length) {
        this.menuList[3].house = data.records;
      }
      this.menuList[3].start = true
    },
    async loadEstateList() {
      const params = {
        ...this.searchParam,
        itemsPerPage: 3,
        cityCode: this.location.cityCode
      };
      const [err, { data }] = await tool.to(houseServer.getEstateList(params));
      if (err) {
        console.log("getEstateList :>>", err);
        return;
      }
      if (data && data.records && data.records.length) {
        this.menuList[1].house = data.records;
      }
      this.menuList[1].start = true
    },
    async loadNewHouseList() {
      const params = {
        ...this.searchParam,
        itemsPerPage: 3,
        cityCode: this.location.cityCode
      };
      const [err, { data }] = await tool.to(
        houseServer.getNewEstatePage(params)
      );
      if (err) {
        console.log("getNewEstatePage :>>", err);
        return;
      }
      if (data && data.length) {
        this.menuList[2].house = data;
      }
      this.menuList[2].start = true
    },
    loadData() {
      this.loadTopHouse();
      this.loadRentHouse();
      this.loadEstateList();
      this.loadNewHouseList();
    },
    updateMenuList() {
      this.menuList[this.menuList.length - 1].subTitle = `高品质租房体验，从${this.companyConfig.firstName}开始`
    },
    init() {
      removeItem(Constant.IS_SORCE_ERP)
      this.$store.commit("updateState", {
        data: false,
        key: "isSourceERP"
      });
    }
  },
  created() {
    this.init()
    this.loadData();
    this.updateMenuList()
    tool.animate(window, 0);
  }
};
</script>

<style scoped lang="less">
.container {
  .mb80 {
    margin-bottom: 80px;
  }

  .mt26 {
    margin-top: 26px;
  }

  .name {
    font-size: 36px;
    line-height: 50px;
  }

  .sub-title {
    color: #9399a5;
    line-height: 22px;
  }

  .sale-house {
    margin-top: 100px;
  }
}
</style>
import request from '../common/request'
import { API_URL } from '../common/Url'
import tool from "../utils/tool"
import { getItem } from '../utils/storage'
import Constant from '../common/Constant'
export default {
    // 获取二手房/租房输入参数
    listEstateByName(params) {
        tool.setCompanyGuid(params)
        params.companyGuid = params.headCompanyGuid
        return request({
            method: 'GET',
            url: API_URL.ESTATE_LIST_GET,
            params
        })
    },
    // 新房参数
    getNewEstateParams(params) {
        tool.setCompanyGuid(params)
        return request({
            method: 'GET',
            url: API_URL.NEW_ESTATE_PARAMS,
            params
        })
    },
    //新房列表
    getNewEstatePage(data) {
        return request({
            method: 'POST',
            url: API_URL.NEW_ESTATE_PAGE_LIST,
            data
        })
    },
    /* 房源列表 */
    getHouseList(data) {
        tool.setCompanyGuid(data)
        return request({
            method: 'POST',
            url: API_URL.SALE_HOUSE,
            data
        })
    },
    /* 房源参数 */
    loadSearchParam(cityCode) {
        const params = { cityCode }
        tool.setCompanyGuid(params)
        return request({
            method: 'GET',
            url: API_URL.SECOND_HOUSE_PARAM,
            params
        })
    },
    /* 小区参数 */
    getEstateParams(cityCode) {
        const params = { cityCode }
        tool.setCompanyGuid(params)
        return request({
            method: 'GET',
            url: API_URL.ESTATE_PARAMETER_GET,
            params
        })
    },
    /* 小区列表 */
    getEstateList(data) {
        return request({
            method: 'GET',
            url: API_URL.ESTATE_LIST_PAGE,
            data
        })
    },
    /* 小智看点 */
    customerArticle(params) {
        return request({
            method: 'GET',
            url: API_URL.CUSTOMER_ARTICLE,
            params
        })
    },
    /* 所有城市 */
    getAllCityTree(data) {
        return request({
            method: 'GET',
            url: API_URL.ALL_CITY_TREE,
            data
        })
    },
    /* ERP城市 */
    getAllCityTreeCompany(data) {
        return request({
            method: 'GET',
            url: "/api/wechat/location/allCity/treeCompany",
            data
        })
    },
    // 发布房源
    submitDemandHouseForm(data) {
        return request({
            method: 'POST',
            url: API_URL.DEMAND_HOUSE_FORM,
            data,
        });
    },
    // 关注房源列表[二手房/租房]
    pageCollection(data) {
        return request({
            method: 'GET',
            url: API_URL.COLLECTION_PAGE,
            data,
        });
    },
    // 取消关注
    cancelCollection(data) {
        return request({
            method: 'POST',
            url: API_URL.CANCEL_COLLECTION,
            data,
        });
    },
    // 关注
    addCollection(data) {
        return request({
            method: 'POST',
            url: API_URL.ADD_COLLECTION,
            data,
        });
    },

    //通过坐标换取地理位置
    getLocalByCoordinate(data) {
        return request({
            method: 'GET',
            url: API_URL.GETLOCALBYCOORDINATE,
            data,
        });
    },
    //  楼盘是否关注
    isEstateAttention(data) {
        return request({
            method: 'GET',
            url: API_URL.ESTATE_ATTENTION,
            data,
        });
    },
    // 楼盘关注-取关
    estateAttention(data) {
        return request({
            method: 'PUT',
            url: API_URL.ESTATE_ATTENTION,
            data,
        });
    },
















    loadCityList(params) {
        return request({
            method: 'GET',
            url: API_URL.CITY_LIST,
            params
        })
    },
    loadCityListV3(params) {
        return request({
            method: 'GET',
            url: API_URL.CITY_LIST_V3,
            params
        })
    },
    loadHouseParams(params) {
        return request({
            method: 'GET',
            url: API_URL.HOUSE_PARAMS,
            params
        })
    },
    loadHouseList(data) {
        return request({
            method: 'POST',
            url: API_URL.HOUSE_LIST,
            data
        })
    },
    loadAreaRegion(data) {
        tool.setCompanyGuid(data)
        return request({
            method: 'POST',
            url: API_URL.AREA_REGION,
            data
        })
    },
    loadStreetList(params) {
        return request({
            method: 'GET',
            url: API_URL.STREET_LIST,
            params
        })
    },
    //获取小区详情
    getOldEstateInfo(guid) {
        return request({
            method: 'GET',
            url: API_URL.OLD_ESTATE_INFO + guid,
        })
    },
    //同小区房源
    getOldEstateHouse(params) {
        return request({
            method: 'GET',
            url: API_URL.SAME_ESTATE_HOUSE_LIST,
            params
        })
    },
    //获取小区带看过的经纪人
    getViewAgent(params) {
        return request({
            method: 'GET',
            url: API_URL.VIEW_ESTATE_AGENT,
            params
        })
    },
    //获取小区周边小区
    getAroundEstate(params) {
        tool.setCompanyGuid(params)
        return request({
            method: 'GET',
            url: API_URL.AROUND_ESTATE_LIST,
            params
        })
    },
    //附近门店
    getNeighborhoodDepartment(params) {
        tool.setCompanyGuid(params)
        return request({
            method: 'GET',
            url: API_URL.ORGANIZE_NEIGHBORHOOD_GET,
            params
        })
    },


    //获取经纪人
    getAgentDataByGuid(params) {
        return request({
            method: 'GET',
            url: API_URL.AGENT_DETAIL.replace("{guid}", params.guid),
        });
    },
    getAgentShopHouse(params) {
        return request({
            method: 'GET',
            url: API_URL.AGENT_HOUSE,
            params

        });
    },
    getCompanyConfig(params = {}) {
        tool.setCompanyGuid(params)
        return request({
            method: 'GET',
            url: API_URL.COMPANY_CONFIG,
            params
        });
    },

    // 根据城市Code加载公司
    getCompanyByCityCode(cityCode) {
        return request({
            method: 'GET',
            url: API_URL.LOAD_COMPANY_BY_CITY_CODE.replace('{cityCode}', cityCode)
        });
    },
    loadTopHouse(params) {
        params.companyGuid = getItem(Constant.CITY_COMPANY) ? getItem(Constant.CITY_COMPANY).headCompanyGuid : ''
        return request({
            method: 'GET',
            url: API_URL.LOAD_TOP_HOUSE,
            params
        });
    },
}
<template>
  <li class="container" :title="houseItem.name">
    <router-link :to="path" target="_blank">
      <div class="cover-wrap">
        <img class="cover" :src="coverImage | returnImage" :onerror="defaultImg" :alt="houseItem.name" />
      </div>
      <div class="title">
        <div class="row sb_center">
          <p class="resblock-name black bold size18 ellipsis">{{houseItem.name}}</p>
          <span style="line-height: 25px;" class="size16 red bold">{{houseItem.price}}元/平</span>
        </div>
        <p v-if="houseItem.completeYear" class="resblock-year  size12 col66">{{houseItem.completeYear}}年建</p>
      </div>
    </router-link>
  </li>
</template>
<script>
import Constant from "@/common/Constant";
export default {
  props: {
    houseItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    path() {
      let result = {
        path: "/houseEstateDetail/2",
        query: {
          guid: this.houseItem.guid
        }
      };
      return result;
    },
    coverImage() {
      return this.houseItem.coverImage || Constant.DEFAULT_HOUSE;
    }
  },
  data() {
    return {
      defaultImg: `this.src="${Constant.DEFAULT_HOUSE}"`
    };
  },
  filters:{
    returnImage(url){
        return url+Constant.IMG_HZ
    }
  },
};
</script>


<style lang="less" scoped>
.container {
  width: 364px;
  &:not(:last-child) {
    margin-right: 29px;
  }
  .cover-wrap {
    width: 100%;
    height: 272px;
    overflow: hidden;
    border-radius: 4px;
  }
  .cover {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: 0.7s ease-in-out;
    transform: scale(1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .title {
    margin-top: 15px;
    .resblock-name {
      line-height: 25px;
      max-width: 260px;
      height: 25px;
    }
    .resblock-year {
      line-height: 20px;
      margin-top: 5px;
    }
  }
}
</style>


<template>
  <div class="container">
    <div class="flex border-b">
      <h2 class="size18 bold" :style="{ paddingBottom: showViceTitle ? '30px' : '10px' }">
        联系经纪人
      </h2>
      <span v-show="showViceTitle" style="margin-left: 30px" class="size14 gray">您可以通过电话联系经纪人</span>
    </div>

    <ul>
      <li class="flex pv20 cursor" v-for="(agent, index) in recommendAgent" :key="agent.guid"
        :class="{ 'border-b': index !== recommendAgent.length - 1 }" v-show="index < 3" @click="goAgentShop(agent)">
        <div class="recommenAvatar">
          <img v-if="agent.avatar" class="all fillet" :src="agent.avatar" alt="" />
          <img v-else class="all fillet" :src="agentAvatar" alt="" />
        </div>
        <div class="flex1">
          <div class="flex sb_start">
            <div>
              <div class="flex s_center">
                <h3>{{ agent.trueName || '经纪人' }}</h3>

              </div>
                    <div class="departmentName size10 mt5">
                        {{ agent.departmentName || "名盛房产" }}
                    </div>
                    <p class="whiteGray size10 pt5">好评经纪人</p>
            </div>
            <div class="flex">
              <div v-if="agent.fixTel || agent.mobile">
                <el-button @click.stop="" size="small" class="greenBG  tButton phoneButton">
                  <i class="el-icon-phone"></i>
                  {{ agent.fixTel || agent.mobile }}
                </el-button>
              </div>
              <div @click.stop="$emit('show-code', agent)" style="margin-left: 10px;">
                <el-button size="small" plain class="greenBG  green tButton chatButton">
                  <i class="el-icon-chat-dot-round green"></i>
                  在线问
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-show="showRecommend && !isSourceERP" class="mt20 pb40 border-b flex" @click="$emit('change-collection')">
      <el-button class="flex1">{{
        isEstateAttention ? "取消关注" : "关注房源"
      }}</el-button>
    </div>
  </div>
</template>
<script>
import Constant from "../common/Constant.js";
import { mapState } from "vuex";
export default {
  name: "",
  props: {
    title: {
      type: String,
      required: true,
    },
    recommendAgent: {
      type: Array,
      required: true,
    },
    showViceTitle: {
      type: Boolean,
      default: false,
      required: false,
    },
    isEstateAttention: {
      default: false,
    },
    showRecommend: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      agentAvatar: Constant.DEFAULT_AVATAR,
    };
  },
  computed: {
    ...mapState(["isSourceERP"])
  },
  methods: {
    goAgentShop(agent) {
      this.$router.push({
        path: "/agentShop",
        query: {
          guid: agent.guid,
        },
      });
    }
  },
};
</script>
<style lang="less" scoped>
.pb40 {
  padding-bottom: 40px;
}

.recommenAvatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 22px;
}

.departmentName {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}

.tButton {
  color: #fff;
  border-radius: 1px;

  .i {
    color: #fff;
  }
}

.phoneButton:hover {
  background: #f0fef8;
  box-sizing: border-box;
  border: #00ae66 1px solid;
  color: #00ae66;

  .i {
    color: #00ae66;
  }
}

.chatButton {
  background: #f0fef8;
  border: #00ae66 1px solid;
  color: #00ae66;

  .i {
    color: #00ae66;
  }
}

.chatButton:hover {
  box-sizing: border-box;
  background: #fff;
  color: #00ae66;
  border: #00ae66 1px solid;

  .i {
    color: #00ae66;
  }
}
</style>
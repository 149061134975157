<template>
  <div class="container">
    <house-header :tradeType="3" :formType="tradeType" />
    <div class="form-box">
      <ul>
        <li class="area row">
          <label class="label-title">小区所在城市</label>
          <div class="row">
            <span>{{ form.cityName }}</span>
            <a @click="isShowCitySelect = true" class="link-btn green" href="javascript:;">切换城市</a>
          </div>
        </li>
        <li>
          <label class="label-title">小区</label>
          <div>
            <input :maxlength="20" v-model="form.estateName" placeholder="请输入小区名" type="text" />
          </div>
        </li>
        <li>
          <label class="label-title">具体地址</label>
          <div>
            <input :maxlength="20" v-model="form.address" placeholder="请输入具体地址" type="text" />
          </div>
        </li>
        <li v-if="tradeType === 2">
          <label class="label-title">期望售价</label>
          <div class="row">
            <input v-model="form.totalPrice" style="width:210px" placeholder="请输入您期望售出的价格" type="text"
              @input="onInput($event)" :maxlength="6" />
            <span class="bold black">万元</span>
          </div>
        </li>
        <li v-else>
          <label class="label-title">期望租金</label>
          <div class="row">
            <input v-model="form.totalPrice" style="width:210px" placeholder="请输入您期望租出的价格" type="text"
              @input="onInput($event)" :maxlength="6" />
            <span class="bold black">元/月</span>
          </div>
        </li>
        <li>
          <label class="label-title">称呼</label>
          <div>
            <input :maxlength="10" v-model="form.customerName" placeholder="我们应该如何称呼您" type="text" />
          </div>
        </li>
        <li>
          <label class="label-title">手机号码</label>
          <div class="flex1">
            <input :maxlength="11" v-model="form.mobile" placeholder="您的联系方式,方便我们及时与您联系" type="text" />
          </div>
        </li>
      </ul>
      <p class="tips">
        提示：您点击“提交委托”后，若您的房源通过平台初步审核，
        将会由平台上的经纪人和您取得联系，并对您的房源进行再次核实，
        核实无误后将与您建立服务关系。{{ companyConfig.firstName }}平台仅提供信息展示和网络技术服务。
      </p>
      <div class="submit-wrap">
        <a @click="onSubmit" class="submit-btn" href="javascript:;">{{ tradeType === 1 ? '提交委托' : '确认发布' }}</a>
      </div>
    </div>
    <div v-if="tradeType === 2" class="process">
      <div class="hd">
        卖房流程
      </div>
      <ul class="row sb_center">
        <li v-for="(item, index) in processList" :key="index" class="row">
          <span class="icon">{{ index + 1 }}</span>
          <div>
            <div class="tit">{{ item.title }}</div>
            <div class="sub-tit">{{ item.subTitle }}</div>
          </div>
        </li>
      </ul>
    </div>
    <city-select :show-city-select="isShowCitySelect" :is-cache="false" @update-city-select="isShowCitySelect = $event"
      @choose-city="chooseCity" />
    <entrust-succes :is-show-success="isShowSuccess" @close-sucess-modal="closeSucessModal" />
  </div>
</template>
<script>
import HouseHeader from "@/components/house-header";
import CitySelect from "@/components/city-select";
import houseServer from "../../services/houseServer.js";
import EntrustSucces from "./components/entrust-succes";
import { mapState } from "vuex";
import tool from "@/utils/tool.js";

export default {
  name: "owner",
  data() {
    return {
      isShowCitySelect: false,
      tradeType: 1,
      processList: [
        {
          title: "发布房源",
          subTitle: "在线上免费发布房源"
        },
        {
          title: "核对房源",
          subTitle: "30分钟内客服联系"
        },
        {
          title: "在线销售",
          subTitle: ""
        },
        {
          title: "签约出售",
          subTitle: "签约过户全城专业服务"
        }
      ],
      form: {
        estateName: "",
        address: "",
        totalPrice: "",
        customerName: "",
        mobile: "",
        type: 1
      },
      isLoding: false,
      isShowSuccess: false
    };
  },
  computed: {
    ...mapState(["user", "location", "companyConfig"])
  },
  watch: {
    $route(newVal, pre) {
      if (newVal.params.status !== pre.params.status) {
        this.tradeType = +newVal.params.status;
      }
    },
    user(newVal, preVal) {
      if (newVal !== preVal && newVal) {
        this.form.mobile = tool.mobileTo2_2(newVal.customer.phone);
      }
    }
  },
  components: {
    HouseHeader,
    CitySelect,
    EntrustSucces
  },
  methods: {
    getTradeType() {
      const {
        params: { status }
      } = this.$route;
      this.tradeType = +status;
      if (status == 2) {
        //卖房委托
        this.form.type = 2;
      } else {
        //帮我出租
        this.form.type = 1;
      }
    },
    getParams() {
      const { cityCode, cityName } = this.location;
      this.form = {
        ...this.form,
        cityCode,
        cityName
      };
      if (this.user) {
        this.form.mobile = tool.mobileTo2_2(this.user.customer.phone);
      }
    },
    checkParam() {
      const map = {
        estateName: "小区名",
        address: "具体地址",
        totalPrice: this.tradeType === 2 ? "期望售价" : "期望租价",
        customerName: "称呼"
      };
      for (const key in this.form) {
        if (!this.form[key]) {
          if (key === "mobile") {
            this.updateShowLogin();
          } else {
            this.$message.error("请填写" + map[key]);
          }
          return false;
        }
      }
      return true;
    },
    async onSubmit() {
      if (!this.checkParam() || this.isLoding) return;
      this.isLoding = true;
      const param = {
        ...this.form,
        regionName: this.form.estateName + this.form.address, //区域
        mobile: this.user.customer.phone
      };
      const [err, { data }] = await tool.to(
        houseServer.submitDemandHouseForm(param)
      );
      if (err) {
        this.$message.error(err.message);
        this.isLoding = false;
        return;
      }
      if (data) {
        this.isLoding = false;
        this.isShowSuccess = true;
      }
    },
    chooseCity({ cityCode, cityName }) {
      this.form = {
        ...this.form,
        cityCode,
        cityName
      };
    },
    onInput(event) {
      this.form.totalPrice = tool.isNumber(event.target.value);
    },
    closeSucessModal() {
      this.isShowSuccess = false;
      const temp = ["mobile", "type", "cityName", "cityCode"];
      for (const key in this.form) {
        if (!temp.includes(key)) {
          this.form[key] = "";
        }
      }
    },
    updateShowLogin() {
      this.$store.commit("updateState", {
        data: true,
        key: "showLogin"
      });
    },
    updateProcessList() {
      const tempProcessList = [...this.processList]
      tempProcessList[2].subTitle = `在${this.companyConfig.firstName}APP移动管理销售`
      this.processList = tempProcessList
    }
  },
  created() {
    this.updateProcessList()
    this.getTradeType();
    this.getParams();
    !this.user && this.updateShowLogin();
  }
};
</script>

<style lang="less" scoped>
.container {
  .form-box {
    color: #9399a5;
    margin: 0 auto;
    width: 590px;
    padding-top: 50px;

    .label-title {
      color: #101d37;
      font-weight: 700;
      width: 120px;
    }

    .link-btn {
      margin-left: 60px;
    }

    .area {
      padding: 0 0 25px;
      line-height: 1;
    }

    ul>li {
      padding: 12px 0;
      line-height: 40px;
      border-bottom: 1px solid #eee;
      display: flex;
    }

    .tips {
      color: #9399a5;
      margin: 30px auto 0;
      line-height: 20px;
    }

    .submit-wrap {
      margin: 60px 0 120px;

      .submit-btn {
        display: block;
        margin: 0 auto;
        width: 190px;
        height: 55px;
        line-height: 55px;
        font-size: 18px;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        background-color: #00ae66;
        cursor: pointer;
      }
    }
  }

  .process {
    width: 990px;
    margin: 0 auto;
    margin-top: 55px;
    margin-bottom: 60px;

    .hd {
      position: relative;
      text-align: center;
      color: #666;

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 10px;
        height: 1px;
        width: 430px;
        background-color: #eee;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    ul {
      padding-top: 45px;
    }

    li {
      .icon {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background-color: #d0d1d2;
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 48px;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }

      .sub-tit {
        font-size: 12px;
        line-height: 20px;
        color: #9399a5;
      }
    }
  }
}
</style>

<template>
  <div class="waiceng">
    <div class="agentShop">
      <div class="agentData row">
        <img
          class="agentImg"
          :src="agentData.avatar?agentData.avatar:defaultAvatar"
          alt=""
        />
        <div class="agentMsg column">
          <div class="row StartEnd mb15">
            <span class="size24">{{ agentData.name || "经纪人" }}</span>
            <div class="agentJob row s_center">

              <span class="agentCompany">{{ agentData.companyName }}</span>

            </div>
          </div>
          <p class="mb10 size15">
            <span class="bold"> 电话： </span>
            <span class="colRed size20">{{agentData.fixTel|| agentData.mobile}}</span>
          </p>
          <!-- <p class="mb10 fontS16">
            <span>经纪人分数：</span>
            <span class="fontS30">{{ agentData.taskScore }}</span>
          </p>
          <p class="mb10 fontS16 colRed">
            <span class="mr10">用户评分</span>
            <span class="fontS30">{{ agentData.score }}</span>
          </p> -->
          <p class="mb10 size15">
            <span class="bold">服务平台年限：</span>
            <span style="color:#666">{{agentData.serviceYear?agentData.serviceYear+'年':'不足一年'}}</span>
          </p>
          <p
            class="mb10 size15"
            v-show="agentData.serviceYear"
          >
            <span class="bold">服务客户：</span>
            <span style="color:#666">{{agentData.servingCustomer+'位'}}</span>
          </p>
          <p
            class="mb10 size15"
            v-show="agentData.serviceYear"
          >
            <span class="bold">综合评分：</span>
            <span style="color:#666">{{agentData.score+'分'}}</span>
          </p>
        </div>
        <div
          class="qrCode column"
          v-show="codePath"
        >
          <img
            class="qrCodeImg mb10"
            :src="codePath"
            alt=""
          />
          <span class="mb10">微信扫码与我联系</span>
          <span
            class="downButton cupo row c_center"
            @click="isShowDownApp = true"
          >
            <img
              class="msg_icon"
              src="./../../assets/imgs/detail_icon/icon_detail_msg_white.png"
              alt=""
            />
            <span>在线咨询</span>
          </span>
        </div>
      </div>
      <div style="height: 50px"></div>
      <div class="agentHouseList">
        <!-- <p class="totle fontS18">
          共找到 <span class="colRed">{{ total }}</span> 套房
          <span class="colRed">精选房源</span>
        </p>
        <p
          class="borderBottomE"
          style="padding: 10px 0"
        >
          <span
            @click="tabTardType(2)"
            class="mr20 cupo"
            :style="tradeType == 2 ? 'color:#4b4bc9;' : 'color:#333'"
          >出售</span>
          <span
            class="cupo"
            @click="tabTardType(1)"
            :style="tradeType == 1 ? 'color:#4b4bc9;' : 'color:#333'"
          >出租</span>
        </p> -->
        <ul class="sort-wrap flex">
          <li
            :class="{ active: tradeType == 2 }"
            @click="tabTardType(2)"
            class="flex c_center cursor ellipsis"
          >
            <span class="size15 bold">在售房源</span>
          </li>
          <!-- <li
            :class="{ active: tradeType ==1 }"
            @click="tabTardType(1)"
            class="flex c_center cursor ellipsis"
          >
            <span class="size15 bold">在租房源</span>
          </li> -->
        </ul>
        <div>
          <house-item
            v-for="item in house"
            :key="item.guid"
            :tradeType="tradeType"
            :houseItem="item"
            :agentGuid="agentGuid"
          />

          <!-- </div> -->
        </div>

        <div
          style="margin: 20px 0"
          class="row e_center"
        >
          <el-pagination
            background
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            @prev-click="handleCurrentChange"
            @next-click="handleCurrentChange"
            :disabled="isLoading"
            layout="prev, pager, next"
            :current-page="page"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <div
      class="downApp"
      v-show="isShowDownApp"
      @click="isShowDownApp = false"
    >
      <div class="qrCodeDiv">
        <img
          v-show="codePath"
          class="qrCodeDiv-img"
          :src="codePath"
          alt=""
        />
        <span class="qrCodeDiv-close">×</span>
        <p style="padding: 5px 0">扫描二维码 下载APP</p>
      </div>
    </div>
  </div>
</template>

<script>
import houseServer from "../../services/houseServer";
import parameterServer from "../../services/parameterServer";
import HouseItem from "./components/house-item";
import Constant from "../../common/Constant.js";
import { mapState } from "vuex";

export default {
  components: {
    HouseItem,
  },
  computed:{
    ...mapState(["companyConfig","isSourceERP"])
  },
  data() {
    return {
      isShowDownApp: false,
      tradeType: 2,
      agentGuid: "",
      agentData: {},

      page: 1,
      pageSize: 10,
      total: 0,
      house: [],
      isLoading: false,

      codePath: "",
      defaultAvatar: Constant.DEFAULT_AVATAR,
    };
  },
  methods: {
    async getQrCodePath() {
      let sceneStr = `agentGuid=${this.agentGuid}`;

      let params = {
        appId: this.companyConfig.appId, //1是看房小程序，2是好房小程序
        page: 11,
        sceneStr: sceneStr,
      };
      // console.log(`params`, params)
      const { data } = await parameterServer.loadQrCodePath(params);
      console.log(`getQrCodePath`, data);
      this.codePath = data.qrCode;
    },
    tabTardType(num) {
      this.tradeType = num;
      this.getAgentHouse();
    },
    async getAgentData() {
      let params = {
        guid: this.agentGuid,
      };
      const { data } = await houseServer.getAgentDataByGuid(params);
      this.agentData = data;
    },
    async getAgentHouse() {
      this.isLoading = true;
      let params = {
        page: this.page,
        itemsPerPage: this.pageSize,
        tradeType: this.tradeType,
        agentGuid: this.agentGuid,
      };
      const { data } = await houseServer.getAgentShopHouse(params);
      this.house = data;
      console.log('getAgentShopHouse>>',data)
    //   this.house = data.records;
    //   this.pages = data.pages;
    //   this.total = data.total;
      this.isLoading = false;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getAgentHouse();
    },
  },
  created() {
    this.agentGuid = this.$route.query.guid;
    this.getAgentData();
    this.getAgentHouse();
    this.getQrCodePath();
  },
};
</script>

<style lang="less" scoped>
.agentShop {
  width: 1200px;
  // margin-top: 60px;
  box-sizing: border-box;
}
.agentData {
  background: #f4f4f4;
  padding: 30px 30px;
  margin-top: 25px;
  .agentImg {
    width: 120px;
    height: 150px;
    margin-right: 20px;
  }
  .agentMsg {
    flex: 1;
    .agentJob {
      .agentCompany {
        line-height: 24px;
        font-size: 12px;
        padding: 0 10px;
        border-radius: 2px;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .qrCode {
    padding: 0 20px;
    align-items: center;
    .qrCodeImg {
      width: 120px;
      height: 120px;
    }
    .downButton {
      line-height: 28px;
      background-color: #00ae66;
      padding: 0 10px;
      color: #fff;
      border-radius: 4px;
      text-decoration: none;
    }
  }
}
.agentHouseList {
  .houseItem {
    color: #333;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    .houseItem-img {
      width: 240px;
      height: 180px;
    }
    .houseInfo {
      flex: 1;
      .title {
        font-size: 24px;
        margin: 0 0 15px;
      }
      .tags {
        .tag {
          color: #fff;
          display: inline-block;
          line-height: 24px;
          padding: 0 5px;
          font-size: 12px;
          border-radius: 4px;
        }
      }
    }
    .house-price {
    }
  }
}
.borderBottomE {
  border-bottom: 1px solid #eee;
}
.msg_icon {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}

.waiceng {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.StartEnd {
  justify-content: flex-start;
  align-items: flex-end;
}
.fontS24 {
  font-size: 24px;
}
.fontS12 {
  font-size: 12px;
}
.fontS14 {
  font-size: 14px;
}
.fontS16 {
  font-size: 16px;
}
.fontS18 {
  font-size: 18px;
}
.fontS30 {
  font-size: 30px;
}
.cupo {
  cursor: pointer;
}
.mb15 {
  margin-bottom: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.colRed {
  color: #ff0000 !important;
}
.colGray {
  color: #999;
}

.downApp {
  width: 100vw;
  height: 100vh;
  //   background: #000;
  background-color: rgba(0, 0, 0, 0.6);
  //   opacity: 0.6;
  position: fixed;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
  .qrCodeDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 1;
    padding: 10px;
    height: auto;
    background-color: #fff;
    box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    z-index: 999;
    text-align: center;
    .qrCodeDiv-img {
      width: 260px;
      height: 260px;
    }
    .qrCodeDiv-close {
      position: absolute;
      right: -10px;
      top: -10px;
      font-size: 20px;
      background-color: #ffffff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      line-height: 25px;
    }
  }
}

.sort-wrap {
  border-bottom: 2px solid #00ae66;
  li {
    width: 120px;
    height: 48px;
    box-sizing: border-box;
  }
  .active {
    background-color: #00ae66;
    color: #ffffff;
  }
}
</style>
<template>
  <li class="container" :title="houseItem.title">
    <router-link :to="path" target="_blank">
      <div class="cover-img relative">
        <img class="all radius5" v-if="houseItem.coverImage" :src="houseItem.coverImage|returnImage" alt="" />
        <img class="all radius5" v-else :src="defaultCover" alt="" />
        <!-- <img v-if="mustSee" class="must-see" src="@/assets/imgs/app_icon_must_see.png" alt="" /> -->
        <span v-if="mustSee" class="must-see size12 redBG flex c_center">
          <img  :src="miniLogo" alt="" />
          {{mustSeeTag}}
        </span>
        <img v-if="houseItem.vrUrl" class="vr-logo" src="@/assets/imgs/icon_vr_gold.gif" />

      </div>
      <div class="bottom" v-if="tradeType === 2">
        <div class="name size18 bold black ellipsis">
          <p class="ellipsis" style="width:100%">{{houseItem.title}}</p>
        </div>
        <div class="info row sb_center mt10 size12 col66">
          <div>
            <span v-if="houseItem.hall">{{houseItem.hall + "室"}}</span>
            <span v-if="houseItem.room">{{houseItem.room + "厅"}}</span>
            <span v-if="houseItem.buildingArea"> · {{houseItem.buildingArea + "平米"}}</span>
          </div>
          <span class="size16 bold red">{{houseItem.price ? houseItem.price + '万' : "暂无数据"}}</span>
        </div>
      </div>
      <div v-else class="bottom">
        <div class="rent-name size18 bold black">
          <span>{{houseItem.title}} · </span>
          <span v-if="houseItem.hall">{{houseItem.hall + "室"}}</span>
          <span v-if="houseItem.room">{{houseItem.room + "厅"}}</span>
        </div>
        <div class="info row sb_center mt10 size12 col66">
          <div style="max-width:70%" class="ellipsis">
            <span v-if="houseItem.estateName">{{houseItem.estateName}} / </span>
            <span v-if="houseItem.hall">{{houseItem.hall + "室"}}</span>
            <span v-if="houseItem.room">{{houseItem.room + "厅"}}</span>
            <span v-if="houseItem.bothRoom">{{houseItem.bothRoom + "卫"}}</span>
          </div>
          <span class="size16 bold red">{{houseItem.rentPrice ? houseItem.rentPrice + '元/月' : "暂无数据"}}</span>
        </div>
      </div>
    </router-link>
  </li>

</template>
<script>
import Constant from "@/common/Constant";
import { mapState } from "vuex";

export default {
  props: {
    tradeType: {
      type: Number,
      required: true
    },
    houseItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['companyConfig']),
    path() {
      let result;
      if (this.tradeType === 2) {
        result = {
          path: "/houseDetail/2",
          query: {
            guid: this.houseItem.guid,
            tradeType: this.tradeType
          }
        };
      } else {
        result = {
          path: "/rentHouseDetail/1",
          query: {
            guid: this.houseItem.guid,
            tradeType: this.tradeType
          }
        };
      }
      return result;
    },
    mustSee() {
      const tag = this.houseItem.tag || [];
      //  || (this.houseItem.houseLevel || 0) == 1 ? true : false
       return tag.includes("必看好房") ||tag.includes("礼包房源") ||tag.includes("特惠好房")
    },
    mustSeeTag() {
      const tag = this.houseItem.tag || [];
      let tagText = ''
      if(tag.includes("礼包房源")){
        return tagText ='礼包房源'
      }else if(tag.includes("特惠好房") ){
return  tagText ='特惠好房'
      }else if(tag.includes("必看好房")){
 return  tagText = '必看好房'
      }
return tagText
    },
    miniLogo() {
      return this.companyConfig.miniLogo
    }
  },
  data() {
    return {
      defaultCover: Constant.DEFAULT_HOUSE
    };
  },
  filters:{
    returnImage(url){
        return url+Constant.IMG_HZ
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 265px;
  margin-bottom: 16px;
  &:not(:nth-of-type(4n)) {
    margin-right: 30px;
  }

  .cover-img {
    width: 265px;
    height: 205px;
    border-radius: 4px;
    .must-see {
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 0 10px;
      height: 30px;
      color: #ffffff;
      background: #d53c3c;
      img {
        width: 15px;
        height: 15px;
        padding-right: 5px;
      }
    }
    .vr-logo {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 8px;
      bottom: 8px;
    }
  }
  .bottom {
    .name {
      margin-top: 16px;
      line-height: 24px;
    }
    .info {
      line-height: 16px;
      overflow: hidden;
    }
    .rent-name {
      line-height: 25px;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 15px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
}
</style>




import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import store from "@/store/index"
const { location, companyConfig } = store.state
const routes = [
    {
        path: '/',
        component: () => import("../page/layout/index.vue"),
        children: [
            {
                path: '', // 默认子路由
                name: '首页',
                component: () => import("../page/home/index.vue"),
                meta: {
                    title: '小智找房_二手房|租房|新房|小区|房地产信息网'
                }
            },
            {
                path: '/house/:tradeType', /*  tradeType 1:租房 2:售房 4:小区 */
                name: '房源列表',
                component: () => import("../page/house/index.vue"),
            },
            {
                path: '/loupan',
                name: '新房列表',
                component: () => import("../page/estate/index.vue"),
                meta: {
                    title: '新房_新楼盘_新房房价信息网'
                }
            },
            {
                path: '/owner/:status',
                name: '业主房源',
                component: () => import("../page/owner/index.vue"),
            },
            {
                path: '/houseDetail/:tradeType', /*  tradeType 1:租房 2:售房 4:小区 */
                name: 'second-house-detail',
                component: () => import("../page/secondHouse/houseDetail.vue"),
            },
            {
                path: '/shopHouseDetail/:tradeType', //店铺房源
                name: 'shop-house-detail',
                component: () => import("../page/secondHouse/shopHouseDetail.vue"),
            },
            {
                path: '/rentHouseDetail/:tradeType', /*  tradeType 1:租房 2:售房 4:小区 */
                name: 'rent-house-detail',
                component: () => import("../page/secondHouse/rentHouseDetail.vue"),
            },
            {
                path: '/houseEstateDetail/:tradeType', /*  tradeType 1:租房 2:售房 4:小区 */
                name: 'house-estate-detail',
                component: () => import("../page/secondHouse/houseEstateDetail.vue"),
            },
            {
                path: '/newEstateDetail',
                name: 'new-estate-detail',
                component: () => import("../page/newHouseDetail/newEstateDetail.vue"),
            },
            {
                path: '/newEstateTab/:chooseKey',
                name: 'new-estate-tab',
                component: () => import("../page/newHouseDetail/newEstateTab.vue"),
            },
            {
                path: '/setting', // 默认子路由
                name: 'setting',
                component: () => import("../page/setting/index.vue"),
            },
            {
                path: '/kandian',//看点
                name: 'kandian',
                component: () => import("../page/highlight/index.vue"),
            },
            {
                path: '/detail/:articleGuid',//看点
                name: 'kandian-detail',
                component: () => import("../page/highlight/detail.vue"),
            },
            {
                path: '/agentShop',
                name: 'agentShop',
                component: () => import("../page/agentShop/agentShop.vue"),
            },
            {
                path: '/zhuanti/:status', // 1: 服务承诺 2:隐私政策
                name: '协议',
                component: () => import("../page/zhuanti/index.vue"),
                meta: {
                    isUpdateIcon: true,
                }
            },
            {
                path: '/servicePromise', // 1: 服务承诺 2:隐私政策
                name: '服务承诺',
                component: () => import("../page/setting/servicePromise.vue"),
                meta: {
                    isUpdateIcon: true,
                }
            },
            {
                path: '/map',
                name: '地图',
                component: () => import("../page/map/index.vue"),
                meta: {
                    isUpdateIcon: true,
                }
            },
            {
                path: '/erpMap',
                name: 'erp地图',
                component: () => import("../page/erpMap/index.vue"),
                meta: {
                    isUpdateIcon: true,
                }
            }
            // {
            //     path: '/newMap',
            //     name: '新地图找房',
            //     component: () => import("../page/newMap/index.vue"),
            //     meta: {
            //         isUpdateIcon: true,
            //     }
            // },
        ]
    },



















]

const router = new VueRouter({
    routes
})
// 网页头部
const getTitle = ({ tradeType, cityName, companyName }) => {
    let result = ''
    switch (tradeType) {
        case '1':
            result += `${cityName}租房信息_${cityName}出租房源|房屋出租价格`
            break
        case '2':
            result += `${cityName}二手房_${cityName}二手房出售买卖信息网`
            break
        default:
            result += `${cityName}小区大全_${cityName}小区房价_${cityName}小区排名排行榜`
    }
    return result += ` [${cityName + companyName}]`
}
// 网页icon
const updateLinkIcon = (icon) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement("link")
    link.rel = "icon"
    link.href = icon
    document.querySelector('head').appendChild(link)
}
router.beforeEach((to, form, next) => {
    const { greenLogo, appName } = companyConfig
    const { cityName } = location
    let title = ""
    if (to.meta?.isUpdateIcon)
        updateLinkIcon(greenLogo)

    if (to.name === "房源列表") {
        const { tradeType } = to.params
        title = getTitle({ tradeType, companyName: appName, cityName })
    } else if (to.meta?.title) {
        title = cityName + to.meta.title
    } else {
        title = appName //此处写默认的 title
    }
    document.title = title
    next()
})


export default router
<template>
  <li class="relative container">
    <div class="list-content row s_start ">
      <router-link :to="path" target="_blank">
        <img alt="封面图" class="image-size" :src="coverImage" :onerror="defaultImg" />
      </router-link>
      <div class="flex1 size14">
        <router-link target="_blank" :to="path" class="title">{{ title }}</router-link>
        <ul class="row s_center ul-list">
          <li class="ul_item">{{ item.propertyType }}</li>
          <li class="ul_item" v-if="item.room" style="color:#E6E6E6;">|</li>
          <li class="ul_item">{{ item.room + "室" + item.hall + "厅" }}</li>
          <li class="ul_item" v-if="item.buildingArea" style="color:#E6E6E6;">|</li>
          <li class="ul_item">{{ item.buildingArea + '平米' }}</li>
          <li class="ul_item" v-if="orientation" style="color:#E6E6E6;">|</li>
        </ul>
        <ul class="row s_center ul-list " style="color:#9399A5;">
          <!-- <li class="ul_item">{{item.propertyType}}</li>
                    <li class="ul_item" v-if="item.currentFloor" style="color:#E6E6E6;">/</li> -->
          <li class="ul_item">{{ item.currentFloor + "(共" + item.totalFloor + "层)" }}</li>
          <!-- <li class="ul_item" v-if="item.buildingArea" style="color:#E6E6E6;">/</li>
                    <li class="ul_item">{{item.buildingArea+'平米'}}</li> -->
          <li class="ul_item" v-if="decorateDesc" style="color:#E6E6E6;">/</li>
          <li class="ul_item">{{ decorateDesc }}</li>
        </ul>
        <ul class="row s_center" style="margin-top:22px;">
          <li class="tag size12" v-for="(ele, index) in tag" :key="ele + index">{{ ele }}</li>
        </ul>
      </div>
      <div>
        <div class="price column c_center">
          <p class="red-price">{{ (!currentTab ? item.price : item.rentPrice) || '--' }}<span v-if="!currentTab"
              style="font-size:20px;"> 万</span></p>
          <p class="gray-unit" v-if='!currentTab && item.unitPrice'>{{ item.unitPrice + ' 元/平' }}</p>
          <p class="red-price" v-if='currentTab' style="margin-top:-12px;"><span style="font-size:20px;">
              元/月</span>
          </p>
        </div>
      </div>
    </div>
    <div style="height:1px;background-color:#E6E6E6;"></div>
    <el-button size="small" type="primary" @click="handleCancel" :disabled="isLoading"
      class="cancel-btn">取消关注</el-button>
  </li>
</template>

<script>
import Constant from "@/common/Constant";
import houseServer from "../../services/houseServer.js";
import tool from "../../utils/tool.js";

export default {
  name: "house",
  props: {
    item: Object,
    currentTab: Number
  },
  data() {
    return {
      defaultImg: `this.src="${Constant.DEFAULT_HOUSE}"`,
      isLoading: false
    };
  },
  computed: {
    path() {
      let result;
      if (this.currentTab === 2) {
        result = {
          path: "/rentHouseDetail/1",
          query: {
            guid: this.item.guid,
            tradeType: 1
          }
        };
      } else {
        result = {
          path: "/houseDetail/2",
          query: {
            guid: this.item.guid,
            tradeType: 2
          }
        };
      }
      return result;
    },
    title: function () {
      let str = "";
      const { title, room, hall, orientation } = this.item;
      if (title) {
        str += title + "   ";
      }
      if (room) {
        str += room + "室" + hall + "厅" + "   ";
      }
      if (orientation && orientation !== "未选择") {
        str += orientation;
      }
      return str;
    },
    orientation: function () {
      const { orientation } = this.item;
      return orientation && orientation != "未选择" ? orientation : "";
    },
    decorateDesc: function () {
      const { decorateDesc } = this.item;
      return decorateDesc && decorateDesc != "未选择" ? decorateDesc : "";
    },
    tag: function () {
      const { tag } = this.item;
      if (Array.isArray(tag)) {
        return tag;
      }
      return [];
    },
    coverImage() {
      return this.item.coverImage || Constant.DEFAULT_HOUSE;
    }
  },
  watch: {},
  components: {},
  methods: {
    async handleCancel() {
      if (this.isLoading) return
      this.isLoading = true
      const param = {
        houseGuid: this.item.guid,
        tradeType: this.currentTab ? 1 : 2
      };
      const [err, { code }] = await tool.to(
        houseServer.cancelCollection(param)
      );
      this.isLoading = false
      if (err) {
        return this.$message.error(err.message);
      }
      if (code === 0) {
        this.$emit("update-house-list");
      }
      this.$message({
        type: "success",
        duration: 1500,
        message: "取消关注成功",
      });
    }
  },
  created() { }
};
</script>

<style scoped lang="less">
@width: 30px;
@height: 160px;

.container:hover>.cancel-btn {
  display: block;
}

.cancel-btn {
  display: none;
  position: absolute;
  right: 0;
  top: -30px;
  background-color: #00ae66;
}

.list-content {
  margin: @width 0;

  .image-size {
    width: 173px;
    height: 130px;
    margin-right: 30px;
    border-radius: 3px;
  }

  .title {
    font-size: 24px;
    color: #00ae66;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  .ul-list {
    color: #101d37;
    font-weight: 500;
    font-family: Hiragino Sans GB;
    margin: 16px 0;

    .ul_item {
      margin-right: 25px;
    }
  }

  .tag {
    border: 1px solid #357eac;
    height: 23px;
    display: inline-block;
    text-align: center;
    line-height: 23px;
    color: #6e96af;
    padding: 0 7px;
    margin-right: 6px;
  }

  .price {
    width: 120px;
    height: @height;

    .red-price {
      color: #fe615a;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 11px;
    }

    .gray-unit {
      color: #9399a5;
      font-size: 14px;
      font-weight: 300;
    }
  }
}
</style>
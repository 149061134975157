<template>
  <div class="mapPeripheral">
    <div class="">
      <h1 class="mapPeripheral-title" :style="titleStyle">周边配套</h1>
      <span></span>
    </div>
    <!-- <BMap :lng="lng" :lat="lat" :estateName="estateName" /> -->
    <div style="
        position: relative;
        width: 100%;
        height: 560px;
        margin: auto;
        display: flex;
      ">
      <baidu-map :center="{ lat: this.lat, lng: this.lng }" style="height: 560px; width: 100%" @ready="loadMap"
        :scroll-wheel-zoom="false">
        <bm-view style="width: 100%; height: 560px; flex: 1"></bm-view>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
        <bm-overlay pane="labelPane" class="zone" @draw="draw">
          <div style="
              width: 8px;
              height: 8px;
              background: #00ae66;
              border: 2px #fff solid;
              border-radius: 6px;
              position: relative;
              left: 0;
              top: 0;
            ">
            <div style="
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #ffffff;
                padding: 0px 26px;
                position: absolute;
                left: -300%;
                bottom: 17px;
                width: max-content;
              ">
              <div style="
                  background: #00ae66;
                  position: relative;
                  left: -50%;
                  padding: 9px 26px;
                ">
                <span>{{ estateName }}</span>

                <div style="
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 12px solid #00ae66;
                    position: absolute;
                    left: 50%;
                    margin-left: -5px;
                    bottom: -6px;
                  "></div>
              </div>
            </div>
          </div>
        </bm-overlay>
        <bm-marker :offset="{ width: 0, height: -15 }" :position="item.point" v-for="item in resultsData"
          :key="item.uid" :icon="{
            url: iconUrl,
            size: { width: 30, height: 30 },
            imageSize: { width: 30, height: 30 },
          }"></bm-marker>
      </baidu-map>
      <div style="
          position: absolute;
          right: 18px;
          top: 20px;
          background-color: #fff;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
          width: 409px;
          height: 509px;
          display: flex;
          flex-direction: column;
        ">
        <div class="tabHeader-top cursor">
          <div v-for="tab in mapTabs" :key="tab.key" :class="tab.key == chooseTab.key ? 'chooseTab' : 'unChooseTab'"
            @click="chooseTabs(tab)">
            {{ tab.key }}
          </div>
        </div>
        <div class="tabHeader-bottom cursor" v-show="chooseTab.key == '交通'">
          <div :class="item.key == childrenTab.key ? 'childrenTab' : 'unChildrenTab'
            " @click="childrenTabs(item)" v-for="item in chooseTab.children" :key="item.key">
            {{ item.key }}
          </div>
        </div>
        <div style="padding-top: 10px; flex: 1; overflow-y: auto">
          <ul class="mapContent">
            <li class="mapContentItem" v-for="item in resultsData" :key="item.uid">
              <div class="mapContentItem-left">
                <img class="mapContentItem-left-icon" :src="listIconUrl" />
              </div>
              <div class="content-top-item">
                <div class="content-top-item-title">
                  <div style="flex: 1">{{ item.title }}</div>
                  <div class="content-top-item-title-right">
                    <img style="margin-right: 15px; width: 16px; height: 16px" :src="require('./../assets/icon/web_map_navigation_black.png')
                      " alt="" />
                    <span>{{ item.distance }}</span>
                  </div>
                </div>
                <div class="itemInfo">{{ item.address }}</div>
              </div>
            </li>
            <li class="mapContentItem" v-if="resultsData.length == 0">
              <span style="font-size: 12px; color: #ccc">很抱歉，该配套下无相关内容，请查看其它配套</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- <span class="transform-btn" @click="onToggle3D">{{ is3D ? "3D" : "2D" }}</span> -->
    </div>
  </div>
</template>

<script>
// import BMap from "./BMap.vue"
export default {
  props: {
    lng: {
      required: true,
    },
    lat: {
      required: true,
    },
    estateName: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    titleStyle: {
      type: String,
    },
  },
  components: {
    // BMap
  },
  data() {
    return {
      ak: "cgR5juzHQGCiXxUutZqKf82L93QIONiG",
      mapTabs: [
        {
          key: "交通",
          iconUrl: require("./../assets/icon/web_map_bus_red.png"),
          listIconUrl: require("./../assets/icon/web_map_bus_black.png"),
          children: [{ key: "公交站" }, { key: "地铁站" }],
        },
        {
          key: "教育",
          iconUrl: require("./../assets/icon/web_map_school_red.png"),
          listIconUrl: require("./../assets/icon/web_map_school_black.png"),
        },
        {
          key: "医疗",
          iconUrl: require("./../assets/icon/web_map_hospital_red.png"),
          listIconUrl: require("./../assets/icon/web_map_hospital_black.png"),
        },
        {
          key: "购物",
          iconUrl: require("./../assets/icon/web_map_shop_red.png"),
          listIconUrl: require("./../assets/icon/web_map_shop_black.png"),
        },
        {
          key: "生活",
          iconUrl: require("./../assets/icon/web_map_teacup_red.png"),
          listIconUrl: require("./../assets/icon/web_map_teacup_black.png"),
        },
        {
          key: "娱乐",
          iconUrl: require("./../assets/icon/web_map_sing_red.png"),
          listIconUrl: require("./../assets/icon/web_map_sing_black.png"),
        },
      ],
      chooseTab: {
        key: "交通",
        children: [{ key: "公交站" }, { key: "地铁站" }],
      },
      childrenTab: { key: "公交站" },
      range: 2000,
      titleLabel: "",
      iconUrl: require("./../assets/icon/web_map_bus_red.png"),
      listIconUrl: require("./../assets/icon/web_map_bus_black.png"),
      resultsData: [], //搜索结果
      isLoading: false,
      is3D: false,

    };
  },
  methods: {
    onToggle3D() {
      const { map, BMap, lng, lat } = this
      const point = new BMap.Point(lng, lat);
      if (this.is3D) {
        map.setTilt(0);
        map.setHeading(0);
        map.centerAndZoom(point, 15);
      } else {
        map.panBy(-320, 0);
        map.setHeading(64.5);
        map.setTilt(73);
      }
      this.is3D = !this.is3D
    },
    loadMap({ BMap, map }) {
      const { lng, lat } = this
      this.BMap = BMap;
      this.map = map;
      const point = new BMap.Point(lng, lat);
      map.centerAndZoom(point, 15);
      map.enableScrollWheelZoom();
      this.searchNearby(BMap, map);
    },
    chooseTabs(tab) {
      if (this.isLoading || this.chooseTab.key === tab.key) return
      this.chooseTab = tab;
      this.iconUrl = tab.iconUrl;
      this.listIconUrl = tab.listIconUrl;
      if (tab.key == "交通") {
        this.childrenTab = tab.children[0];
      }
      this.searchNearby(this.BMap, this.map);
    },
    childrenTabs(tab) {
      if (this.childrenTab.key === tab.key) return
      this.childrenTab = tab;
      this.searchNearby(this.BMap, this.map);
    },
    searchNearby(BMap, map) {
      // map.clearOverlays();//清除地图覆盖物
      let key = "公交";
      if (this.chooseTab.key == "交通") {
        key = this.childrenTab.key;
      } else {
        key = this.chooseTab.key;
      }
      let mPoint = new BMap.Point(this.lng, this.lat);
      var local = new BMap.LocalSearch(map, {
        renderOptions: { autoViewport: false },
      });
      local.setPageCapacity(20);
      local.disableFirstResultSelection();
      local.searchNearby(key, mPoint, this.range);
      this.searchComplete(local, this.BMap, this.map);
    },
    searchComplete(local, BMap, map) {
      let data = [];
      local.setSearchCompleteCallback((results) => {
        for (var i = 0; i < results.getCurrentNumPois(); i++) {
          let poi = results.getPoi(i);
          poi.distance =
            map
              .getDistance(
                new BMap.Point(this.lng, this.lat),
                new BMap.Point(poi.point.lng, poi.point.lat)
              )
              .toFixed(0) + "米";
          // console.log(poi)
          data.push(poi);
        }
      });
      this.resultsData = data;
    },
    draw({ BMap, map, el }) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(this.lng, this.lat));
      el.style.left = pixel.x - 4 + "px";
      el.style.top = pixel.y - 4 + "px";
    },
  },
};
</script>

<style scoped>
.transform-btn {
  position: absolute;
  left: 60px;
  top: 20px;
  padding: 0 12px;
  height: 30px;
  line-height: 30px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #00ae66;
  font-weight: bold;
  cursor: pointer;
  z-index: 100;
}

.mapPeripheral-title {
  height: 33px;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  color: #333;
  margin-bottom: 26px;
}

.map-estateName-po {
  width: 8px;
  height: 8px;
  background: #00ae66;
  border: 2px #fff solid;
  border-radius: 6px;
  position: relative;
  left: 0;
  top: 0;
}

.map-estateName {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  padding: 9px 26px;
  position: absolute;
  left: 0;
  bottom: 10px;
  background: #00ae66;
}

.map-estateName-i {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid #00ae66;
  position: absolute;
  left: 50%;
  bottom: -6px;
}

.tabHeader-top {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
}

.chooseTab {
  flex: 1;
  height: 45px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  background: #fff;
  color: #00ae66;
  box-sizing: border-box;
  border-top: #00ae66 4px solid;
}

.unChooseTab {
  flex: 1;
  height: 45px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  background: #f1f1f1;
  color: #808080;
  box-sizing: border-box;
  border-top: #f1f1f1 4px solid;
}

.tabHeader-bottom {
  margin-top: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}

.childrenTab {
  flex: 0 0 25%;
  color: #00ae66;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  border-bottom: #00ae66 solid 2px;
}

.unChildrenTab {
  flex: 0 0 25%;
  color: #808080;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  border-bottom: #fff solid 2px;
}

.mapContent {
  width: 100%;
}

.mapContentItem {
  width: 100%;
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.mapContentItem-left {
  width: 20px;
  margin-right: 15px;
}

.mapContentItem-left-icon {
  width: 20px;
  height: 20px;
}

.content-top-item {
  flex: 1;
}

.content-top-item-title {
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-top-item-title-right {
  width: 95px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.itemInfo {
  font-size: 12px;
  color: #cccccc;
  line-height: 17px;
  margin-top: 4px;
}

.zone {
  width: 1px;
  height: 1px;
  background: #00ae66;
  border: 0px #fff solid;
  border-radius: 0px;
  position: relative;
  left: 0;
  top: 0;
}
</style>
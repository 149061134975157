<template>
  <div class="container">
    <div class="flex sb_center mb20">
      <h1 class="size24 col4d bold">
        {{ estateName }}{{ tradeType == 2 ? "优质二手房" : "正在出租" }}
      </h1>
      <span
        class="size14 cursor gray"
        @click="toHouseList"
      >{{ tradeType == 2 ? "查看小区全部在售二手房" : "查看小区全部出租房" }}
      </span>
    </div>
    <ul class="flex pt5">
      <router-link
        :title="item.title"
        class="sale-hosue-item mr10"
        v-for="item in recommendHouse"
        :to="{

          name: tradeType == 2 ? 'second-house-detail' : 'rent-house-detail',
          query: {
            guid: item.guid,
            tradeType: tradeType,
          },
        }"
        target="_blank"
        :key="item.guid"
      >
        <div
          class="pb10 relative"
          style="height: 160px"
        >
          <img
            class="all"
            :src="
              item.coverImage
                ? item.coverImage
                : 'https://resource.fzhitong.cn/default_house_cover.png'
            "
            alt=""
          />
          <div
            v-show="tradeType == 2 && item.price"
            class="absolute sale-price size12"
          >
            {{ "￥" + item.price + "万" }}
          </div>
        </div>
        <p
          class="ellipsis size14 bold black pb5"
          style="width: 100%"
        >
          {{ item.title }}
        </p>
        <div class="flex sb_center">
          <div class="flex size12 gray">
            <span v-if="item.buildingArea">{{
              item.buildingArea + "平/"
            }}</span>
            <span v-if="item.room">{{ item.room + "室" }}</span>
            <span v-if="item.hall">{{ item.hall + "厅" }}</span>
          </div>
          <div
            v-show="tradeType == 1 && item.rentPrice"
            class="size12 col4d flex s_center"
          >
            <span class="red">{{ item.rentPrice }}</span>
            <span>元/月</span>
          </div>
        </div>
      </router-link>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SaleHouseView",
  props: {
    estateName: {
      type: String,
      required: true,
    },
    recommendHouse: {
      type: Array,
      required: true,
    },
    tradeType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      type: this.tradeType == 2 ? 1 : 4,
      statisType: this.tradeType == 2 ? 3 : 4,
    };
  },
  methods: {
    toHouseList() {
      if (this.tradeType == 2) {
        this.$router.push({
          path: "/house/2",
          query: {
            estateName: this.estateName,
          },
        });
      } else {
        this.$router.push({
          path: "/house/1",
          query: {
            estateName: this.estateName,
          },
        });
      }
    },
    enterDetail(item) {
      this.$router.push({
        name: "second-house-detail",
        params: {
          guid: item.guid,
          tradeType: this.tradeType,
        },
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.sale-hosue-item {
  width: 33%;
}
.sale-price {
  left: 0;
  bottom: 24px;
  padding: 0 6px;
  height: 24px;
  background-color: #101d37;
  color: #fff;
  line-height: 24px;
}
</style>
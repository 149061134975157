<template>
  <div v-if="showCitySelect" class="modal">
    <div class="modal-inner">
      <div class="close-btn" @click="$emit('update-city-select', false)">
        <i class="el-icon-close size20 white bold"></i>
      </div>
      <div class="title flex sb_center pb10 border-b">
        <h1 class="size22 col4d bold">选择城市</h1>
        <div v-if="hotCity.length" class="flex size14">
          <span class="whiteGray">热门城市:</span>
          <ul class="flex col4d">
            <li class="ml10 cursor" v-for="item in hotCity" @click="chooseCity(item)" :key="item.cityCode">{{ item.city
              }}
            </li>
          </ul>
        </div>
      </div>
      <div class="all-city pv20">
        <ul class="flex wrap" :style="isSourceERP ? '' : 'height:100%;overflow-y:auto'">
          <li v-for="item in allCity" :key="item.pinyin" style="width:50%" class="city-item size14 shrink">
            <p v-if="item.pinYin" class="whiteGray mb10">{{ item.pinYin }}</p>
            <ul class="flex  col4d wrap">
              <li class="cursor pl10  shrink mb10" style="line-height:30px" v-for="ele in item.cities"
                @click="chooseCity(ele)" :key="ele.cityCode">
                {{ ele.city }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import houseServer from "@/services/houseServer.js";
import tool from "@/utils/tool";
import Constant from "@/common/Constant"
import { setItem } from '@/utils/storage'
import { mapState } from "vuex";

export default {
  name: "city-select",
  props: {
    showCitySelect: {
      type: Boolean,
      required: true
    },
    isCache: {
      type: Boolean,
      default: true
    },
    city: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      hotCity: [],
      allCity: []
    };
  },
  computed: {
    ...mapState(["isSourceERP"]),
  },
  methods: {
    async getgetAllCityTreeCompany() {
      const [err, { data }] = await tool.to(houseServer.getAllCityTreeCompany());
      if (err) {
        console.log("getAllCityTree :>>", err);
        return;
      }
      // console.log("getAllCityTreeCompany :>>", data);
      this.hotCity = data.popularCityTree;
      this.allCity = data.cityTree;
    },
    async getAllCityTree() {
      const [err, { data }] = await tool.to(houseServer.getAllCityTree());
      if (err) {
        console.log("getAllCityTree :>>", err);
        return;
      }
      // console.log("getAllCityTree :>>", JSON.stringify(data));
      this.hotCity = data.popularCityTree;
      this.allCity = data.cityTree;
    },
    async getCompanyCitys() {
      const [err, { data: { citys } }] = await tool.to(houseServer.getCompanyConfig());
      if (err) {
        console.log("getCompanyConfig :>>", err);
        return
      }
      const _citys = [{
        pinYin: '',
        cities: JSON.parse(citys) || []
      }]
      this.allCity = _citys;
    },
    chooseCity(e) {
      const { cityCode, city, lat, lng } = e;
      const location = {
        cityCode,
        cityName: city,
        latitude: lat,
        longitude: lng
      };
      if (this.isCache) {
        this.$store.commit("saveLocation", location);
        this.setCompanyByCityCode(location.cityCode)
        // 刷新页面更新title
        setTimeout(() => {
          this.$router.go(0)
        }, 500)
      } else {
        this.$emit("choose-city", location);
      }
      this.$emit("update-city-select", false);

    },
    async setCompanyByCityCode(cityCode) {
      try {
        const { data = {} } = await houseServer.getCompanyByCityCode(cityCode)
        this.$store.commit("updateState", { data: data || {}, key: 'cityCompany' });
        setItem(Constant.CITY_COMPANY, data)
      } catch (e) {
        console.log('setCompanyBycityCode :>> ', e)
      }
    }
  },
  created() {
    if (Constant.DEFAULT_COMPANY_GUID) {
      return this.getCompanyCitys()
    }
    this.isSourceERP ? this.getgetAllCityTreeCompany() : this.getAllCityTree();
  }
};
</script>

<style scoped lang="less">
@keyframes ani-modal {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  80% {
    opacity: 1;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-inner {
  width: 660px;
  height: 440px;
  box-sizing: border-box;
  animation: ani-modal 500ms;
  animation-fill-mode: both;
  margin: -220px 0 0 -330px;
  transition: all 500ms;

  .title {
    margin: 30px 40px 0;
  }
}

.close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: #434343;
  border-radius: 15px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  cursor: pointer;
}

.all-city {
  height: 359px;
  box-sizing: border-box;
  overflow: hidden;
}

.city-item {
  padding: 0 20px;
  box-sizing: border-box;
}
</style>
<template>
  <div>
    <div class=" row">
      <div class="avatar mr10" @click="goAgentShop">
        <img v-if="agentData.avatar" :src="agentData.avatar" class="all fillet" alt="">
        <img v-else :src="defaultAvatar" class="all fillet" alt="">
      </div>
      <div class="pl5 pt5">
        <div class="flex s_center">
          <h2 @click="goAgentShop" class="size20 bold">{{ agentData.trueName || '经纪人' }}</h2>
          <div class="departmentName ml20 size12">{{ agentData.departmentName || '名盛房产' }}</div>
        </div>
        <p class="size12 pt5" style="color:#CCCCCC">好评经纪人</p>
      </div>

    </div>
    <div class="mt20 flex">
      <el-button :style="tradeType == 1 ? 'width:200px;box-sizing: border-box;height:54px' : ''"
        class="tButton phoneButton greenBG size18 " :class="tradeType == 1 ? '' : 'flex1'"
        v-if="agentData.fixTel || agentData.mobile">
        <i class=" el-icon-phone"></i>
        {{ agentData.fixTel || agentData.mobile }}
      </el-button>
      <el-button :style="tradeType == 1 ? 'width:200px;box-sizing: border-box;height:54px' : ''"
        @click="$emit('show-code', agentData)" plain class="tButton chatButton greenBG  green"
        :class="tradeType == 1 ? 'size18' : 'size16'">
        <i class=" el-icon-chat-dot-round "></i>
        在线问
      </el-button>
    </div>
  </div>
</template>
<script>
import Constant from "../common/Constant.js";
import { mapState } from "vuex";
export default {
  name: "AgentData",
  props: {
    agentData: {
      type: Object,
      required: true,
    },
    tradeType: {
      default: 2,
    },
  },
  data() {
    return {
      defaultAvatar: Constant.DEFAULT_AVATAR,
    };
  },
  methods: {
    goAgentShop() {
      this.$router.push({
        path: "/agentShop",
        query: {
          guid: this.agentData.guid,
        },
      });
    }
  },
  computed: {
    ...mapState(["isSourceERP"])
  },
};
</script>
<style scoped lang="less">
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.departmentName {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}

.tButton {
  color: #fff;
  border-radius: 1px;

  .i {
    color: #fff;
  }
}

.phoneButton:hover {
  background: #f0fef8;
  box-sizing: border-box;
  border: #00ae66 1px solid;
  color: #00ae66;

  .i {
    color: #00ae66;
  }
}

.chatButton {
  background: #f0fef8;
  border: #00ae66 1px solid;
  color: #00ae66;

  .i {
    color: #00ae66;
  }
}

.chatButton:hover {
  box-sizing: border-box;
  background: #fff;
  color: #00ae66;
  border: #00ae66 1px solid;

  .i {
    color: #00ae66;
  }
}
</style>
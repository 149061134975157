<template>
  <div class="carousel">
    <img @click="isShowBigImg = true" class="carousel-img" :src="present_img.serverPath
      ? present_img.serverPath
      : imgs.length > 0 && imgs[0].serverPath
        ? imgs[0].serverPath
        : carousel_img
      " alt="" />
    <!-- <img class="carousel-img" :src="carousel_img" alt="" /> -->
    <img v-if="present_img.isVr" src="@/assets/imgs/icon_vr_gold.gif" class="carousel-cupo" alt="" @click="goVr()" />
    <img v-if="present_img.isVideo && (tradeType == 1 || (tradeType == 2 && !isSourceERP))"
      src="../assets/icon/video.png" class="carousel-cupo" alt="" @click="goVideo()" />

    <div class="viewBox-noScroll" v-if="imageList.length > 0">
      <div ref="viewBox" class="viewBox">
        <div ref="imgList" style="left: 0" class="imgList">
          <div :ref="selectNum == index ? 'selectItem' : ''" v-for="(i, index) in imageList" @click="selectImage(index)"
            :key="index" class="img-item" :class="selectNum == index ? 'select' : ''">
            <img style="width: 100%; height: 100%" :src="i.serverPath ? i.serverPath : carousel_img" alt="" />
            <div class="msak"></div>
          </div>
        </div>
      </div>
      <span @click="leftClick()" class="viewBox-button column c_center viewBox-left">
        <i style="color: #fff" class="el-icon-arrow-left"></i>
      </span>
      <span @click="rightClick()" class="viewBox-button column c_center viewBox-right">
        <i style="color: #fff" class="el-icon-arrow-right"></i>
      </span>
    </div>
    <BigImgCarousel v-if="imgs" :isShowBigImg="isShowBigImg" :houseImage="imgs" :newImage="present_img"
      @close="isShowBigImg = false" />
  </div>
</template>

<script>
import Constant from "@/common/Constant.js";
import BigImgCarousel from "./BigImgCarousel.vue";
import { mapState } from "vuex";

export default {
  components: {
    BigImgCarousel,
  },
  props: {
    imgs: {
      type: Array,
    },
    coverImage: {
      type: String,
    },
    vrUrl: {
      type: String,
    },
    videoImageUrl: {
      type: String,
    },
    videoUrl: {
      type: String,
    },
    size: {
      type: String, //min  , max
    },
    tradeType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      selectNum: 0,
      //   imgs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      imageList: [],
      present_img: {},
      carousel_img: Constant.DEFAULT_HOUSE, // require("../assets/imgs/detail_icon/default-house.png"),
      num: 1,
      carousel_num: [],
      selectPageNum: 1,
      loading: false,

      isShowBigImg: false,
    };
  },
  computed: {
    ...mapState(["isSourceERP"])
  },
  methods: {
    leftClick() {
      if (this.loading) {
        return;
      }

      if (this.selectNum > 0) {
        this.selectNum += -1;
        this.present_img = this.imageList[this.selectNum];
      } else {
        this.selectNum = this.imageList.length - 1;
        this.present_img = this.imageList[this.selectNum];
      }
      let carousel_left = this.$refs.imgList.offsetLeft;
      let min_carousel_left = Math.abs(carousel_left) + 92;
      // let min_carousel_left = Math.abs(carousel_left);
      let selectItem_left = this.$refs.selectItem[0].offsetLeft;
      if (min_carousel_left >= selectItem_left) {
        this.carouselToLeft();
      }
    },
    rightClick() {
      if (this.loading) {
        return;
      }

      if (this.selectNum < this.imageList.length - 1) {
        this.selectNum += 1;
        this.present_img = this.imageList[this.selectNum];
      } else {
        this.selectNum = 0;
        this.present_img = this.imageList[0];
      }
      let carousel_left = this.$refs.imgList.offsetLeft;
      let max_carousel_left = Math.abs(carousel_left) + 404;
      let selectItem_left = this.$refs.selectItem[0].offsetLeft;
      if (max_carousel_left <= selectItem_left) {
        this.carouselToRight();
      }
    },
    carouselToLeft() {
      let carousel_width = this.$refs.imgList.clientWidth;
      let carousel_left = this.$refs.imgList.offsetLeft;

      if (carousel_left + 510 >= 0 && carousel_left != 0) {
        this.animation(carousel_left, 0);
        this.selectPageNum += -1;
      } else if (carousel_left + 510 < 0) {
        this.animation(carousel_left, carousel_left + 510);
        this.selectPageNum += -1;
      } else {
        this.animation(carousel_left, -carousel_width + 501);
        this.selectPageNum = this.num;
      }
    },
    carouselToRight() {
      let carousel_width = this.$refs.imgList.clientWidth;
      let carousel_left = this.$refs.imgList.offsetLeft;

      if (
        carousel_width + carousel_left - 510 <= 501 &&
        carousel_width + carousel_left - 510 > 0
      ) {
        this.animation(carousel_left, -carousel_width + 501);
        this.selectPageNum += 1;
      } else if (carousel_width + carousel_left - 510 > 501) {
        this.animation(carousel_left, carousel_left - 510);
        this.selectPageNum += 1;
      } else {
        this.animation(carousel_left, 0);
        this.selectPageNum = 0;
      }
    },
    selectImage(index) {
      this.selectNum = index;
      this.present_img = this.imageList[index];
    },
    animation(start, end) {
      this.loading = true;
      //time 单位ms
      let distance = Math.abs(end - start);
      let toDistance = 0;
      let time = setInterval(() => {
        toDistance = toDistance + 5;
        if (end - start > 0) {
          this.$refs.imgList.style.left = start + toDistance + "px";
        } else {
          this.$refs.imgList.style.left = start - toDistance + "px";
        }
        if (toDistance >= distance) {
          clearInterval(time);
          this.loading = false;
        }
      }, 1);
    },
    goVr() {
      window.open(this.vrUrl);
    },
    goVideo() {
      let videoUrl = this.videoUrl
      if (this.isSourceERP) {
        videoUrl = videoUrl.substring(0, videoUrl.length - 4) + ".webm"
      }
      window.open(videoUrl)
    },
  },
  watch: {
    imgs(newList) {
      newList.forEach((item) => {
        this.imageList.push(item);
      });

      if (this.imageList.length) {
        let num = parseInt(this.imageList.length / 5);
        if (this.imageList.length % 5 > 0) {
          num = num + 1;
        }
        this.num = num;
      }
    },
    vrUrl(newUrl) {
      if (newUrl) {
        let vr = {
          serverPath: this.videoImageUrl
            ? this.coverImage
            : this.imgs[0].serverPath ? this.imgs[0].serverPath : '',
          isVr: true,
        };
        this.imageList.splice(0, 0, vr);
        if (this.imageList.length) {
          let num = parseInt(this.imageList.length / 5);
          if (this.imageList.length % 5 > 0) {
            num = num + 1;
          }
          this.num = num;
        }
      }
    },
    videoUrl(newUrl) {
      if (newUrl) {
        let video = {
          serverPath: this.videoImageUrl ? this.videoImageUrl : this.coverImage,
          isVideo: true,
        };
        this.imageList.splice(0, 0, video);
        if (this.imageList.length) {
          let num = parseInt(this.imageList.length / 5);
          if (this.imageList.length % 5 > 0) {
            num = num + 1;
          }
          this.num = num;
        }
      }
    },
    imageList(newList) {
      this.present_img = newList[0];
    },
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.carousel {
  width: 565px;
  position: relative;
  left: 0;
  top: 0;

  .carousel-img {
    width: 565px;
    height: 319px;
    margin-bottom: 10px;
  }

  .carousel-cupo {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 250px;
    top: 127px;
    cursor: pointer;
  }

  .viewBox-noScroll {
    width: 565px;
    height: 62px;
    overflow-y: hidden;
    position: relative;
    left: 0;
    top: 0;

    .viewBox {
      width: 501px;
      overflow-x: hidden;
      overflow-y: hidden;
      height: 62px;
      margin: 0 32px;
      padding-bottom: 20px;
      position: relative;
      left: 0;
      top: 0;

      .imgList {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-wrap: nowrap;
        height: 62px;

        .img-item {
          width: 92px;
          height: 62px;
          z-index: 50;
          cursor: pointer;
          position: relative;
        }

        .img-item:not(:last-child) {
          margin-right: 9px;
        }
      }
    }

    .viewBox-button {
      width: 24px;
      height: 62px;
      z-index: 60;
      text-align: center;
      background-color: #171717;
      opacity: 0.5;
      cursor: pointer;
    }

    .viewBox-left {
      position: absolute;
      left: 0;
      top: 0px;
      border-radius: 0 4px 4px 0;
    }

    .viewBox-right {
      position: absolute;
      right: 0;
      top: 0px;
      border-radius: 4px 0 0 4px;
    }
  }
}

.msak {
  width: 92px;
  position: absolute;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 60;
  background: rgba(255, 255, 255, 0.3);
}

.msak:hover {
  background: rgba(255, 255, 255, 0);
}

.select {
  .msak {
    background: none !important;
  }
}
</style>
<template>
    <li class="container mt20">
        <router-link :to="path" :title="houseItem.name" target="_blank">
            <img style="height:128px;width:100%" :src="coverImage|returnImage" alt="" />
            <div class="mt10 name-wrap row sb_center size14 black">
                <span class="ellipsis">{{houseItem.name}}</span>
                <div class="row">
                    <i class="type mr5 status">在售</i>
                    <i class="type">{{houseItem.propertyTypeDesc}}</i>
                </div>
            </div>
            <div class="mt10 row size14 bold black">
                <span class="red">18000</span>
                <span>&nbsp;元/平</span>
            </div>
        </router-link>
    </li>
</template>
<script>
import Constant from "@/common/Constant";
export default {
  props: {
    houseItem: {
      type: Object,
      require: true
    }
  },
  computed: {
    path() {
      let result = {
        name: "new-estate-detail",
        query: {
          guid: this.houseItem.guid
        }
      };
      return result;
    },
    coverImage() {
      return this.houseItem.coverImage || Constant.DEFAULT_HOUSE;
    }
  },
  filters:{
    returnImage(url){
        return url+Constant.IMG_HZ
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  .name-wrap {
    span {
      max-width: 82px;
    }
    i {
      height: 16px;
      font-size: 12px;
      line-height: 16px;
      padding: 0 4px;
      box-sizing: border-box;
      border-radius: 2px;
      font-style: normal;
    }
    .type {
      border: 1px solid #ced2d6;
      color: #9399a5;
      line-height: 14px;
    }
    .status {
      color: #3072f6;
      background: rgba(107, 153, 246, 0.11);
    }
  }
}
</style>


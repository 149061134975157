<template>
  <li class="estate-wrap row">
    <router-link class="cover-img" :to="path" target="_blank">
      <img :src="coverImage | returnImage" />
      <ul v-if="showVr" class="row icon-wrap">
        <li>
          <img class="all" src="@/assets/imgs/icon_vr_gold.gif" alt="" />
        </li>
      </ul>
      <span class="watermark">效果图</span>
    </router-link>
    <div class="info flex1">
      <div class="info-name row">
        <router-link class="ellipsis size22 black bold" :to="path" target="_blank" :title="houseItem.name">{{ houseItem.name }}</router-link>
        <span class="greenBG">在售</span>
        <span :style="{backgroundColor:houseItem.propertyTypeDesc === '住宅' ? '#fb9252' :'#8FD24E'}">{{
          houseItem.propertyTypeDesc
          }}</span>
      </div>
      <div class="location row s_center mt15 size14 whiteGray ellipsis">
        <i class="el-icon-location-outline pr10"></i>
        <span>{{ houseItem.address }}</span>
      </div>
      <div class="location row s_center mt15 size14 whiteGray ellipsis">
        <i class="el-icon-s-home pr10"></i>
        <span v-if="filterRooms">{{ filterRooms }}</span>
        <span class="ml10">建面 {{ houseItem.areas }} m²</span>
        <span class="ml10" v-if="houseItem.firstSellTime">开盘时间 {{ houseItem.firstSellTime | datetime }}</span>
      </div>
      <ul class="mt15 row tag-wrap">
        <li v-for="tag in houseItem.tags.filter((_, i) => i < 6)" :key="tag">
          {{ tag }}
        </li>
      </ul>
      <div class="price-wrap">
        <div class="main-price red">
          <span class="number size28">{{
            houseItem.averagePrice || "价格待定"
            }}</span>
          <span v-show="houseItem.averagePrice" class="size14">&nbsp;元/㎡(均价)</span>
        </div>
        <div class="second" v-show="houseItem.totalPrices">
          总价{{ houseItem.totalPrices }}(万/套)
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Constant from "@/common/Constant";
export default {
  props: {
    houseItem: {
      type: Object,
      require: true
    }
  },
  computed: {
    path() {
      let result = {
        name: "new-estate-detail",
        query: {
          guid: this.houseItem.guid
        }
      };
      return result;
    },
    showVr() {
      let res = false;
      this.houseItem.tags.forEach(_ => {
        if (_.indexOf("VR") > -1) {
          res = true;
        }
      });
      return res;
    },
    coverImage() {
      return this.houseItem.coverImage || Constant.DEFAULT_HOUSE;
    },
    filterRooms() {
      const keys = ["room1", "room2", "room3", "room4", "room5", "room6"];
      let result = "";
      for (const key in this.houseItem) {
        if (keys.includes(key) && this.houseItem[key]) {
          const roomNum = key.slice(key.length - 1);
          result += (result ? "/" : "") + roomNum + "室";
        }
      }
      return result;
    }
  },
  filters:{
    returnImage(url){
        return url+Constant.IMG_HZ
    }
  },
};
</script>

<style lang="less" scoped>
.estate-wrap {
  position: relative;
  padding: 30px 0;
  border-bottom: 1px solid #f1f1f1;
  .cover-img {
    position: relative;
    width: 236px;
    height: 178px;
    img {
      width: 100%;
      height: 100%;
    }
    .watermark {
      position: absolute;
      bottom: 12px;
      right: 12px;
      opacity: 0.8;
      font-size: 14px;
      color: #fff;
      font-weight: 300;
    }
  }
  .icon-wrap {
    position: absolute;
    bottom: 10px;
    left: 10px;
    li {
      width: 30px;
      height: 30px;
    }
  }
  .info {
    margin-left: 39px;
    position: relative;
    .info-name {
      a {
        display: inline-block;
        max-width: 75%;
        line-height: 22px;
        margin-right: 7px;
      }
      span {
        display: inline-block;
        padding: 0 7px;
        margin-right: 7px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        vertical-align: middle;
        border-radius: 2px;
        letter-spacing: -0.27px;
        text-align: center;
        font-weight: 400;
        color: #fff;
      }
    }
    .location {
      width: 470px;
    }
    .tag-wrap {
      li {
        display: inline-block;
        height: 30px;
        margin-right: 10px;
        padding: 0 12px;
        line-height: 30px;
        font-size: 12px;
        color: #849aae;
        background: rgba(132, 154, 174, 0.1);
      }
    }
    .price-wrap {
      position: absolute;
      top: 51px;
      right: 0;
      .number {
        line-height: 28px;
      }
      .second {
        margin-top: 10px;
        font-size: 12px;
        color: #9399a5;
        text-align: right;
      }
    }
  }
}
</style>


